import { all } from 'redux-saga/effects';
import authSagas from '@sagas/Auth';
import notesSagas from '@sagas/Notes';
import UsersSaga from '@uhe_sagas/configuration/users/UsersSaga';
import GuestSaga from '@uhe_sagas/GuestSaga';
import InterpreterSaga from '@uhe_sagas/InterpreterSaga';
import ProfileSaga from '@uhe_sagas/ProfileSaga';
import ClinicianSaga from '@uhe_sagas/ClinicianSaga';
import EndpointsSaga from '@uhe_sagas/EndpointsSaga';
import DeviceControlSaga from '@uhe_sagas/DeviceControlSaga';

/**
 * Root Sagas
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([
    authSagas(),
    notesSagas(),

    // UHE sagas below
    UsersSaga(),
    GuestSaga(),
    InterpreterSaga(),
    ProfileSaga(),
    ClinicianSaga(),
    EndpointsSaga(),
    DeviceControlSaga(),
  ]);
}
