import React from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { injectIntl } from 'react-intl';
import { hideMessage } from '@actions/Common';

/**
 * Global notifications component. Mapped to common actions and store.
 */
class UHESystemNotifications extends React.Component {
  componentDidUpdate(prevProps) {
    const { error, message, intl, warning_msg } = this.props;

    if (!message && !error && !warning_msg) {
      return;
    }

    // display errror message
    if (prevProps.error !== this.props.error || prevProps.message !== this.props.message || prevProps.warning_msg !== this.props.warning_msg) {
      const { message, error } = this.props;

      if (error.name === 'HTTPResponseError' || error.name === 'ServerError') {
        const translationKey = `uhe.serverMessages.${error.message}`;
        const translation = intl.formatMessage({ id: translationKey });
        const translatedText = translationKey === translation ? error.message : translation;

        notification.error({
          message: 'Error',
          description: translatedText,
        });
      }

      if (error.name === 'HTTPResponseWarning') {
        notification.warning({
          message: 'Warning',
          description: intl.formatMessage({ id: `uhe.serverMessages.${error.message}` }),
        });
        this.props.clearMessages();
      }

      if (message) {
        notification.success({
          message: 'Success',
          description: intl.formatMessage({ id: `uhe.serverMessages.${message}` }),
        });
        this.props.clearMessages();
      }

      if (warning_msg) {
        notification.warning({
          message: 'Warning',
          description: intl.formatMessage({ id: `uhe.serverMessages.${warning_msg}` }),
        });
        this.props.clearMessages();
      }
    }
  }

  render() {
    return '';
  }
}

/**
 * @description Maps Global State to Component's Props
 * @returns {Object}
 */
const mapStateToProps = ({ common }) => {
  const { error, message, warning_msg } = common;

  return {
    message,
    error,
    warning_msg
  };
};

/**
 * @description Maps Global Dispatch to Component's Props
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch) => ({
  clearMessages: () => dispatch(hideMessage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(UHESystemNotifications));
