import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  INVITE_INTERPRETER_REQUEST, INVITE_INTERPRETER_SUCCESS, VMR_REQUEST_INT, VMR_SUCCESS_INT,
} from '@constants/UHEActionTypes';

/**
   * getVmr Action
   * @returns {object} VMR Data
   */
export const getVmr = () => ({
  type: VMR_REQUEST_INT,
});

/**
   * getVmrSuccess Action
   * @param {object} payload Data
   * @returns {object} VMR Data
   */
export const getVmrSuccess = (payload) => ({
  type: VMR_SUCCESS_INT,
  payload,
});

/**
   * inviteInterpreter Action
   * @param {object} payload Body
   * @returns {object} Data
   */
export const inviteInterpreter = (payload) => ({
  type: INVITE_INTERPRETER_REQUEST,
  payload,
});

/**
   * inviteInterpreterSuccess Action
   * @param {object} payload Body
   * @returns {object} Data
   */
export const inviteInterpreterSuccess = (payload) => ({
  type: INVITE_INTERPRETER_SUCCESS,
  payload,
});

/**
   * getLanguages Action
   * @param {object} payload Body
   * @returns {object} Data
   */
export const getLanguages = (payload) => ({
  type: GET_LANGUAGES,
  payload,
});

/**
   * getLanguagesSuccess Action
   * @param {object} payload Body
   * @returns {object} Data
   */
export const getLanguagesSuccess = (payload) => ({
  type: GET_LANGUAGES_SUCCESS,
  payload,
});
