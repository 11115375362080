import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';
import IntlMessages from '@util/IntlMessages';
import { connect } from 'react-redux';

import ParticipantsList from '@components/aps/participants/ParticipantsList';
import { Button, Divider } from 'antd';
import closeIcon from '@root/assets/images/invite_details_arrow_closed_over.svg';

/**
 * ParticipantsPage Class Component
 * @returns {void}
 */
class ParticipantsPage extends Component {
  /**
   * ParticipantsPage Constructor
   * @param {object} props ParticipantsPage Props
   */
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  backButtonHandler = () => {
    const { history } = this.props;

    history.push('/aps-options');
  }

  /**
   * Renders ParticipantsPage Component {
   * @returns {JSX.Element} ParticipantsPage Component
   */
  render() {
    const { participants, intl } = this.props;
    return (
      <section className="participants-page">
        <div className="headline-flex">
          <Button
            className="chat-back-btn"
            size="small"
            type="text"
            icon={<img src={closeIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-back-img" />}
            onClick={this.backButtonHandler}
          />
          <h1 className="headline">
            <IntlMessages id="participants.pageTitle" />
          </h1>
          <div />
        </div>
        <Divider className="chat-divider" />
        <div className="participants-page_body">
          <ParticipantsList participants={participants} />
        </div>
      </section>
    );
  }
}

ParticipantsPage.propTypes = {
  history: PropTypes.shape().isRequired,
  participants: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
};

/**
 * Maps State to Props
 * @returns {object} Object with States
 */
const mapStateToProps = ({ pexip }) => ({
  participants: pexip.participants,
});

export default injectIntl(withRouter(connect(mapStateToProps, null)(ParticipantsPage)));
