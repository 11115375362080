import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IntlMessages from '@util/IntlMessages';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Radio,
} from 'antd';
import {
  SMS_VALUE, EMAIL_VALUE, PHONE_VALUE, PEXIP_URL,
} from '@constants/UHESettings';
import { getVmr, inviteParticipant } from '@uhe_actions/GuestActions';
import PexRTC from '@util/PexRTC';
import closeIcon from '@root/assets/images/invite_details_arrow_closed_over.svg';

/**
 * GuestPage
 * @returns {void}
 */
class GuestPage extends Component {
  formRef = React.createRef();

  /**
   * GuestPage Constructor
   * @param {object} props GuestPage Props
   */
  constructor(props) {
    super(props);
    const { location } = this.props;

    this.state = {
      type: SMS_VALUE,
      participants: '',
    };

    this.qParams = new URLSearchParams(location.search);
    this.mdmToken = this.qParams.get('mdm_token');
  }

  /**
   * Handles Subscriptions
   * @returns {void}
   */
  componentDidMount() {
    const { getVmr, inCall } = this.props;

    if (!inCall) {
      getVmr();
    }
  }

  /**
   * Redirect to /aps-options page if aps device is online
   * @returns {void}
   */
  componentDidUpdate() {
    const { type } = this.state;
    const {
      history, redirectToApsOptionsPage, vmr, hostName,
    } = this.props;
    if (redirectToApsOptionsPage === true) {
      history.replace('/aps-options');
    }
    if (type === PHONE_VALUE) {
      PexRTC.onSetup = () => { };
      if (Object.keys(vmr).length > 0) {
        PexRTC.makeCall(PEXIP_URL, vmr?.conference?.aliases[0]?.alias, hostName, null, 'none');
      }
    }
  }

  /**
   * Shows Placeholder on the Input Field Based on the Selected Invite Type
   * @returns {void}
   */
  getPlaceholder = () => {
    const { type } = this.state;

    switch (type) {
      case SMS_VALUE:
        return 'common.phoneNumber';
      case PHONE_VALUE:
        return 'common.phoneNumber';
      case EMAIL_VALUE:
        return 'common.email';
      default:
        return ' ';
    }
  };

  /**
   * Handle dalOutCallBac
   * @param {string} value dialOut response
   * @returns {void}
   */
  dialOutCallBack = (value) => {
    const { history } = this.props;
    const { result } = value;
    const [uuid] = result;
    if (uuid) {
      history.push('/aps-options');
    }
  }

  /**
   * Handles invite
   * @returns {void}
   */
  sendInvite = () => {
    const { participants, type } = this.state;
    const { inviteParticipant, vmr } = this.props;
    const inviteBody = {
      to: participants,
      body: '',
      vmr: vmr.conference.aliases[0].alias,
    };
    const apsBody = {
      unique_id: sessionStorage.getItem('aps_id'),
      command: 'api',
      params_p: {
        vmr: vmr.conference.aliases[0].alias,
        announce: true,
      },
    };

    if (participants && type !== PHONE_VALUE) {
      inviteParticipant({ inviteBody, apsBody });
    } else {
      inviteParticipant({ apsBody });
      PexRTC.dialOut(participants, 'auto', 'guest', (value) => this.dialOutCallBack(value));
    }
  }

  /**
   * Handles close button
   * @returns {void}
   */
  redirectOnClose = () => {
    const { history } = this.props;
    history.goBack();
  }

  /**
   * Renders GuestPage Component
   * @returns {JSX.Element} GuestPage Component
   */
  render() {
    const { type } = this.state;
    const { intl } = this.props;

    return (
      <Form className="gx-d-flex gx-flex-column gx-align-items-center gx-mt-5" onFinish={this.sendInvite} ref={this.formRef}>
        <div className="headline-flex">
          <Button
            className="chat-back-btn"
            size="small"
            type="text"
            icon={<img src={closeIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-back-img" />}
            onClick={this.redirectOnClose}
          />
          <h1 className="headline">
            <IntlMessages id="guest.inviteGuests" />
          </h1>
          <div />
        </div>
        <Form.Item className="form-item-wrapper">
          <Radio.Group
            defaultValue={SMS_VALUE}
            size="large"
            onChange={(event) => this.setState((prevState) => {
              if (prevState.type !== event.target.value) {
                this.formRef.current.setFieldsValue({
                  participants: '',
                });

                return { type: event.target.value, participants: '' };
              }
              return { type: event.target.value };
            })}
            buttonStyle="solid"
            className="guest-radio-group"
          >
            <Radio.Button value={SMS_VALUE} className="guest-radio-btn">
              <IntlMessages id="guest.smsInvite" />
            </Radio.Button>
            <Radio.Button value={EMAIL_VALUE} className="guest-radio-btn">
              <IntlMessages id="guest.emailInvite" />
            </Radio.Button>
            <Radio.Button value={PHONE_VALUE} className="guest-radio-btn">
              <IntlMessages id="guest.phoneInvite" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        {
          type === EMAIL_VALUE
            ? (
              <Form.Item
                name="participants"
                className="input-text-wrapper"
                rules={[
                  {
                    type: 'email',
                    message: intl.formatMessage({ id: 'common.invalidEmail' }),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: this.getPlaceholder() })}
                  className="input-text-field"
                  onChange={(event) => this.setState({ participants: event.target.value })}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="participants"
                className="input-text-wrapper"
                rules={[
                  {
                    pattern: new RegExp(/^\+?[1-9]\d{1,14}$/),
                    message: intl.formatMessage({ id: 'guest.invalidNumber' }),
                  },
                  { min: 7, message: intl.formatMessage({ id: 'guest.minNumVal' }) },
                  { max: 10, message: intl.formatMessage({ id: 'guest.maxNumVal' }) },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: this.getPlaceholder() })}
                  className="input-text-field"
                  onChange={(event) => this.setState({ participants: event.target.value })}
                />
              </Form.Item>
            )
        }
        <Form.Item className="submit-btn-wrapper">
          <Button className="guest-submit-btn" htmlType="submit">
            {type === PHONE_VALUE ? (
              <IntlMessages id="guest.call" />
            ) : (
              <IntlMessages id="guest.invite" />
            )}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

GuestPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  vmr: PropTypes.shape().isRequired,
  getVmr: PropTypes.func.isRequired,
  inviteParticipant: PropTypes.func.isRequired,
  redirectToApsOptionsPage: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  inCall: PropTypes.bool.isRequired,
};

/**
 * Maps State to Props
 * @param {string} guest destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ guest, endpoints }) => ({
  vmr: guest.vmr,
  inCall: guest.inCall,
  redirectToApsOptionsPage: guest.redirectToApsOptionsPage,
  hostName: endpoints.endpoint.name,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getVmr: () => dispatch(getVmr()),
  inviteParticipant: (payload) => dispatch(inviteParticipant(payload)),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestPage)));
