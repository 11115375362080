const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'japan',
    locale: 'ja',
    name: '日本人',
    icon: 'jp'
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'عربى',
    icon: 'sa',
  }

];
export default languageData;
