export const BASE_URL = '/api';
export const BASE_SSO = '/sso';
export const BASE_MOB = '/mobapi';
export const BASE_RUBY = '/aps';
export const ENDPOINTS = {
  users: {
    ownProfile: '/authentication/principal',
  },
  sso: '/api/delegate',
  guest: {
    vmr: '/api/me/vmr',
    invite: '/api/invite/participant',
    aps: '/api/v1/rooms/control',
  },
  interpreter: {
    invite: '/api/invite/interpreter',
    vmr: '/api/me/vmr',
    languages: '/api/invite/interpreter?customer=',
  },
  clinician: {
    patientCall: '/api/patient-call/aps',
    endpoints: '/endpoints',
  },
  me: '/api/me',
  endpoints: '/aps/endpoints',
  deviceControl: '/api/v1/rooms/control',
  endCall: '/api/v1/rooms/control',
  pins: '/api/v1/cameras',
};
