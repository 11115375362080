import React, { Component } from 'react';
import IntlMessages from '@util/IntlMessages';
import { Divider, Button, List } from 'antd';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import closeIcon from '@root/assets/images/invite_details_arrow_closed_over.svg';
import PropTypes from 'prop-types';
import { getEntertainment } from '@uhe_actions/DeviceControlActions';
import howToCastImg from '@root/assets/images/entertainment-page/mobile_cast.svg';

/**
 * Entertainment Page Component
 */
export class EntertainmentPage extends Component {
  /**
   * componentDidMount
   * @returns {void}
   */
  componentDidMount() {
    const { getEntertainmentAction, uniqueID } = this.props;
    const entertainmentCommand = { unique_id: uniqueID, command: 'entertainment_control', params_p: 'on' };
    getEntertainmentAction({ entertainmentCommand });
  }

  /**
   * Handles close button
   * @returns {void}
   */
    redirectOnClose = () => {
      const { history, getEntertainmentAction, uniqueID } = this.props;
      const entertainmentCommand = { unique_id: uniqueID, command: 'entertainment_control', params_p: 'off' };
      getEntertainmentAction({ entertainmentCommand });
      history.replace('/main');
    }

    /**
  * Renders the component
  * @returns {JSX} renders the component
  */
    render() {
      const { intl } = this.props;
      const data = [
        {
          title: <IntlMessages id="entertainment.firstParagraph" />,
        },
        {
          title: <IntlMessages id="entertainment.secondParagraph" />,
        },
      ];
      return (
        <div>
          <div className="headline-flex">
            <Button
              className="chat-back-btn"
              size="small"
              type="text"
              icon={<img src={closeIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-back-img" />}
              onClick={this.redirectOnClose}
            />
            <h1 className="headline">
              <IntlMessages id="entertainment.title" />
            </h1>
            <div />
          </div>
          <Divider className="chat-divider" />
          <p className="entertainment-subtitle">
            <IntlMessages id="entertainment.subtitle" />
          </p>
          <List
            split={false}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(paragraph) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<i className="icon icon-circle entertainment-icon" />}
                  title={<p className="entertainment-p">{paragraph.title}</p>}
                />
              </List.Item>
            )}
          />
          <div className="entertainment-cast-icon">
            <img src={howToCastImg} alt={intl.formatMessage({ id: 'entertainment.castIcon' })} />
          </div>
        </div>
      );
    }
}

EntertainmentPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

/**
 * Maps State to Props
 * @param {string} guest destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ endpoints }) => ({
  uniqueID: endpoints.endpoint.unique_id,
});
/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getEntertainmentAction: (payload) => dispatch(getEntertainment(payload)),
});

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EntertainmentPage)),
);
