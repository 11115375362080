import { PATIENT_CALL_REQUEST, PATIENT_CALL_SUCCESS } from '@constants/UHEActionTypes';

const INIT_STATE = {
  patient: {},
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PATIENT_CALL_REQUEST:
      return { ...state, loading: true };

    case PATIENT_CALL_SUCCESS:
      return { ...state, patient: { ...action.payload }, loading: false };

    default:
      return state;
  }
};
