import enMessages from "../locales/en_US.json";
import saMessages from "../locales/ar_SA.json";
import lodash from 'lodash';

const saMerge = lodash.merge(lodash.cloneDeep(enMessages), lodash.cloneDeep(saMessages))

const saLang = {
  messages: {
    ...saMerge,
  },
  //antd: antdSA,
  locale: 'ar',
  direction: 'rtl'
};
export default saLang;
