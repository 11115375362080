import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from '@reducers/Settings';
import Auth from '@reducers/Auth';
import Notes from '@reducers/Notes';
import Contact from '@reducers/Contact';
import Common from '@reducers/Common';
import LoadingReducer from '@uhe_reducers/loadingReducer';
import SubtitleReducer from '@uhe_reducers/subtitleReducer';
import UsersReducers from '@uhe_reducers/configuration/users/UsersReducers';
import GuestReducers from '@uhe_reducers/GuestReducers';
import ProfileReducer from '@uhe_reducers/ProfileReducer';
import ClinicianReducers from '@uhe_reducers/ClinicianReducers';
import InterpreterReducer from '@uhe_reducers/InterpreterReducer';
import EndpointsReducer from '@uhe_reducers/EndpointsReducer';
import DeviceControlReducer from '@uhe_reducers/DeviceControlReducer';
import PexipReducers from '@uhe_reducers/PexipReducers';

/**
 * reducers index
 * @param {object} history router state
 * @returns {void}
 */
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,

  // Add UHE reducers below
  loading: LoadingReducer,
  ConfigurationUsers: UsersReducers,
  subtitle: SubtitleReducer,
  guest: GuestReducers,
  interpreter: InterpreterReducer,
  profile: ProfileReducer,
  clinician: ClinicianReducers,
  endpoints: EndpointsReducer,
  deviceControl: DeviceControlReducer,
  pexip: PexipReducers,
});

export default createRootReducer;
