import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

/**
 * Renders Recieved Chat Message
 * @param {object} conversation Message Data
 * @param {string} user User's Name
 * @returns {JSX.Element} Recieve Chat Message
 */
const ReceivedMessageCell = ({ conversation, user }) => (
  <div className="gx-chat-item">
    <Avatar
      className="gx-size-40 gx-align-self-end"
      alt="avatar"
    >
      {user.substring(0, 3).toUpperCase()}
    </Avatar>
    <div className="gx-bubble-block chat-bubble-block">
      <div className="chat-name-block recieved-msg">
        <span>
          {user}
        </span>
      </div>
      <div className="gx-bubble chat-message-receive-block">
        <div className="gx-message chat-message">{conversation.message}</div>
        <div className="gx-time gx-text-muted gx-text-right gx-mt-2">{conversation.lastAt}</div>
      </div>
    </div>
  </div>
);

ReceivedMessageCell.propTypes = {
  user: PropTypes.shape().isRequired,
  conversation: PropTypes.shape().isRequired,
};

export default ReceivedMessageCell;
