import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Badge } from 'antd';
import PropTypes from 'prop-types';
import IntlMessages from '@util/IntlMessages';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { redirectToApsOptions, setInCall } from '@uhe_actions/GuestActions';
import { endCall } from '@uhe_actions/EndpointsActions';
import logo from '@root/assets/images/logo_caregility_lg.svg';
import inviteIcon from '@root/assets/images/aps-options/invite.svg';
import devControlIcon from '@root/assets/images/aps-options/device_controls.svg';
import devSettingsIcon from '@root/assets/images/aps-options/settings.svg';
import participantsIcon from '@root/assets/images/aps-options/participants.svg';
import micOnIcon from '@root/assets/images/aps-options/mic_on.svg';
import micOffIcon from '@root/assets/images/aps-options/mic_off.svg';
import videoOnIcon from '@root/assets/images/aps-options/video_on.svg';
import videoOffIcon from '@root/assets/images/aps-options/video_off.svg';
import selfViewIcon from '@root/assets/images/aps-options/self_view.svg';
import {
  tabControl, tabSettings, getSelfView, selfMuteAudio, selfMuteVideo,
  setMicStatus, setCamStatus, setSelfViewStatus,
} from '@uhe_actions/DeviceControlActions';
import { TAB_CONTROL, TAB_SETTINGS } from '@constants/UHEActionTypes';
import { PEXIP_URL } from '@constants/UHESettings';
import PexRTC from '@util/PexRTC';
import { updatePaticipant, removeParticipant, chatMessage } from '@uhe_actions/PexipActions';
import chatIcon from '@root/assets/images/aps-options/chat_mobile.svg';
import moment from 'moment';
import controlCommand from '@util/ControlCommands';

/**
 * Aps Options Page Component
 */
export class ApsOptionsPage extends Component {
  /**
   * ApsOptionsPage Constructor
   * @param {object} props ApsOptionsPage Props
   */
  constructor(props) {
    super(props);

    this.state = {
      msgCount: 0,
    };
  }

  /**
   * Reset guest/redirect property in Store
   * @returns {void}
   */
  componentDidMount() {
    const {
      redirectToApsOptionsAction, vmr, hostName, clinicianAlias,
    } = this.props;

    const lastVmr = sessionStorage.getItem('vmr');

    redirectToApsOptionsAction(false);

    PexRTC.onParticipantUpdate = this.getParticipant;
    PexRTC.onParticipantDelete = this.removeParticipant;
    PexRTC.onChatMessage = this.getMessage;

    PexRTC.onSetup = () => { };
    if (!vmr.length) {
      PexRTC.makeCall(PEXIP_URL, lastVmr, hostName, null, 'none');
    }
    if (Object.keys(vmr).length > 0) {
      PexRTC.makeCall(PEXIP_URL, vmr?.conference?.aliases[0]?.alias, hostName, null, 'none');
    }

    if (clinicianAlias) {
      PexRTC.makeCall(PEXIP_URL, clinicianAlias, hostName, null, 'none');
    }
  }

  /**
   * getMessage
   * @param {string} message message body
   * @returns {object} chatMessageAction
   */
     getMessage = (message) => {
       const { msgCount } = this.state;
       const { chatMessageAction } = this.props;
       const { origin, payload } = message;
       const lastAt = moment().format('LTS');
       const msgBody = {
         display_name: origin, message: payload, type: 'receive', lastAt,
       };
       chatMessageAction(msgBody);
       this.setState({ msgCount: msgCount + 1 });
     }

  /**
   * Dispatches Action to the Store with the Participant Data
   * @param {object} participant Participant Data
   * @returns {void}
   */
  getParticipant = (participant) => {
    const { updatePaticipantAction } = this.props;
    const updatedParticipant = {};
    updatedParticipant[participant.uuid] = { ...participant };

    updatePaticipantAction(updatedParticipant);
  }

  /**
   * Remove Participant Callback
   * @param {object} participant Participant Data
   * @returns {void}
   */
  removeParticipant = (participant) => {
    const { removeParticipantAction } = this.props;

    removeParticipantAction(participant);
  }

  /**
   * Toggle Microphone
   * @returns {void}
   */
  toggleMicHandler = () => {
    const {
      uniqueID, selfAudioAction, micStatus, setMicStatusAction,
    } = this.props;
    const selfAudioCommand = controlCommand(uniqueID, 'microphone_control', micStatus ? 'off' : 'on');
    selfAudioAction({ selfAudioCommand });
    setMicStatusAction(!micStatus);
  }

  /**
   * Toggle Video
   * @returns {void}
   */
  toggleVideoHandler = () => {
    const {
      uniqueID, selfVideoAction, camStatus, setCamStatusAction,
    } = this.props;
    const selfVideoCommand = controlCommand(uniqueID, 'video_control', camStatus ? 'off' : 'on');
    selfVideoAction({ selfVideoCommand });
    setCamStatusAction(!camStatus);
  }

  /**
   * Ends meeting and redirect to /main page
   * @returns {void}
   */
  endMeetingHander = () => {
    const {
      history,
      endCallAction,
      inCall,
      setInCallAction,
    } = this.props;
    const body = {
      unique_id: sessionStorage.getItem('aps_id'),
      command: 'final_end_call',
      params_p: '',
    };

    if (inCall) {
      PexRTC.disconnectAll();
    }

    endCallAction(body);
    setInCallAction();

    history.replace('/main');
  }

  /**
   * Self View Feature
   * @returns {void}
   */
  handleSelfview = () => {
    const {
      uniqueID, getSelfViewAction, setSelfViewStatusAction, selfViewStatus,
    } = this.props;
    const selfViewCommand = { unique_id: uniqueID, command: 'selfview_control', params_p: selfViewStatus ? 'off' : 'on' };
    getSelfViewAction({ selfViewCommand });
    setSelfViewStatusAction(!selfViewStatus);
  }

  /**
   * Renders ApsOptionsPage Component
   * @returns {JSX.Element} ApsOptionsPage Component
   */
  render() {
    const {
      intl, tabControlAction, tabSettingsAction, micStatus, camStatus,
    } = this.props;
    const {
      msgCount,
    } = this.state;

    return (
      <div className="aps-options">
        <div className="aps-options_head">
          <h2 className="room-title"><IntlMessages id="apsOptions.roomTitle" /></h2>
          <button type="button" className="end-button" onClick={this.endMeetingHander}>
            <IntlMessages id="apsOptions.end" />
          </button>
        </div>
        <div className="aps-options_intro">
          <img src={logo} alt={intl.formatMessage({ id: 'common.caregilityLogo' })} className="logo" />
        </div>
        <div className="aps-options_body">
          <Row gutter={(8, 8)}>
            <Col span={6}>
              <Link to="/guest" className="option">
                <img
                  className="option-image"
                  src={inviteIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.inviteParticipants' })}
                />
                <span className="option-title">
                  <IntlMessages id="apsOptions.inviteParticipants" />
                </span>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/chat" className="option">
                <Badge count={msgCount} className="badge-count" overflowCount={9} />
                <img
                  className="option-image"
                  src={chatIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.message' })}
                />
                <span className="option-title">
                  <IntlMessages id="apsOptions.message" />
                </span>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/device-control" className="option" onClick={() => tabControlAction(TAB_CONTROL)}>
                <img
                  className="option-image"
                  src={devControlIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.deviceControl' })}
                />
                <span className="option-title">
                  <IntlMessages id="apsOptions.deviceControl" />
                </span>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/device-control" className="option" onClick={() => tabSettingsAction(TAB_SETTINGS)}>
                <img
                  className="option-image"
                  src={devSettingsIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.deviceSettings' })}
                />
                <span className="option-title">
                  <IntlMessages id="apsOptions.deviceSettings" />
                </span>
              </Link>
            </Col>
          </Row>
          <Row gutter={(8, 8)}>
            <Col span={6}>
              <Link to="/participants" className="option">
                <img
                  className="option-image"
                  src={participantsIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.participants' })}
                />
                <span className="option-title">
                  <IntlMessages id="apsOptions.participants" />
                </span>
              </Link>
            </Col>
            <Col span={6}>
              <div className="option" onKeyUp={this.toggleMicHandler} onClick={this.toggleMicHandler} role="button" tabIndex={0}>
                <img
                  className="option-image"
                  src={micStatus ? micOnIcon : micOffIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.mic' })}
                />
                <span className={`option-title ${micStatus && 'mic-cam-titles'}`}>
                  <IntlMessages id="apsOptions.mic" />
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div className="option" onKeyUp={this.toggleVideoHandler} onClick={this.toggleVideoHandler} role="button" tabIndex={0}>
                <img
                  className="option-image"
                  src={camStatus ? videoOnIcon : videoOffIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.video' })}
                />
                <span className={`option-title ${camStatus && 'mic-cam-titles'}`}>
                  <IntlMessages id="apsOptions.video" />
                </span>
              </div>
            </Col>
            <Col span={6}>
              <div className="option" onClick={() => this.handleSelfview()}>
                <img
                  className="option-image"
                  src={selfViewIcon}
                  alt={intl.formatMessage({ id: 'apsOptions.selfView' })}
                />
                <span className="option-title">
                  <IntlMessages id="apsOptions.selfView" />
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

ApsOptionsPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  redirectToApsOptionsAction: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  endCallAction: PropTypes.func.isRequired,
  vmr: PropTypes.shape().isRequired,
  updatePaticipantAction: PropTypes.func.isRequired,
  tabControlAction: PropTypes.func.isRequired,
  removeParticipantAction: PropTypes.func.isRequired,
  inCall: PropTypes.bool.isRequired,
  setInCallAction: PropTypes.func.isRequired,
  tabSettingsAction: PropTypes.func.isRequired,
  hostName: PropTypes.string.isRequired,
  clinicianAlias: PropTypes.string.isRequired,
};

/**
 * Maps State to Props
 * @returns {object} Object with States
 */
const mapStateToProps = ({
  guest, endpoints, pexip, clinician, deviceControl,
}) => ({
  vmr: guest.vmr,
  clinicianAlias: clinician?.patient?.room?.conference?.aliases[0]?.alias,
  hostName: endpoints.endpoint.name,
  participants: pexip.participants,
  inCall: guest.inCall,
  messages: pexip.messages,
  uniqueID: endpoints.endpoint.unique_id,
  micStatus: deviceControl.micStatus,
  camStatus: deviceControl.camStatus,
  selfViewStatus: deviceControl.selfViewStatus,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  redirectToApsOptionsAction: (payload) => dispatch(redirectToApsOptions(payload)),
  tabControlAction: (payload) => dispatch(tabControl(payload)),
  tabSettingsAction: (payload) => dispatch(tabSettings(payload)),
  endCallAction: (payload) => dispatch(endCall(payload)),
  setInCallAction: () => dispatch(setInCall()),
  updatePaticipantAction: (payload) => dispatch(updatePaticipant(payload)),
  removeParticipantAction: (payload) => dispatch(removeParticipant(payload)),
  chatMessageAction: (payload) => dispatch(chatMessage(payload)),
  getSelfViewAction: (payload) => dispatch(getSelfView(payload)),
  selfAudioAction: (payload) => dispatch(selfMuteAudio(payload)),
  selfVideoAction: (payload) => dispatch(selfMuteVideo(payload)),
  setMicStatusAction: (payload) => dispatch(setMicStatus(payload)),
  setCamStatusAction: (payload) => dispatch(setCamStatus(payload)),
  setSelfViewStatusAction: (payload) => dispatch(setSelfViewStatus(payload)),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(ApsOptionsPage)));
