import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import PexRTC from '@util/PexRTC';

import micOnIcon from '@root/assets/images/aps-options/mic_on.svg';
import micOffIcon from '@root/assets/images/aps-options/mic_off.svg';
import removeIcon from '@root/assets/images/aps-options/remove_mobile.svg';
import chatIcon from '@root/assets/images/aps-options/chat_mobile.svg';
import placeholder_image from '@root/assets/images/placeholder.jpg';

/**
 * ParticipantsItem Class Component
 * @returns {void}
 */
class ParticipantsItem extends Component {
  /**
   * ParticipantsItem Constructor
   * @param {object} props ParticipantsItem Props
   */
  constructor(props) {
    super(props);
    this.participant = props.participant;

    this.state = {
      isParticipantMicOn: true,
    };
  }

  /**
   * Mute/Unmute Participant
   * @param {string} uuid Participant ID
   * @returns {void}
   */
  muteParticipant = (uuid) => {
    const { isParticipantMicOn } = this.state;
    this.setState((prevState) => ({ isParticipantMicOn: !prevState.isParticipantMicOn }), () => {
      PexRTC.setParticipantMute(uuid, isParticipantMicOn);
    });
  }

  /**
   * Disconnects Participant from the Call
   * @returns {void}
   */
  disconnectParticipant = () => {
    const { participant } = this.props;

    PexRTC.disconnectParticipant(participant.uuid);
  }

  /**
   * Renders ParticipantsItem Component
   * @returns {JSX.Element} ParticipantsItem Component
   */
  render() {
    const { intl, participant } = this.props;
    const {
      display_name,
      protocol,
      is_muted,
      uuid,
    } = participant;

    return (
      <>
        {(protocol === 'webrtc' || protocol === 'sip') ? (
          <li className="participants-item">

            <div className="participants-item_image">
              <img
                className="participant-image"
                src={placeholder_image}
                alt={intl.formatMessage({ id: 'participants.userImage' })}
              />
            </div>

            <div className="participants-item_content">
              <div className="participant-details">
                <div className="participant-name">
                  <p className="fullname">
                    {display_name}
                  </p>
                </div>

                <div className="participant-options">
                  <Button
                    className="participant-mic-btn"
                    onClick={() => this.muteParticipant(uuid)}
                  >
                    <img
                      className="option"
                      src={is_muted === 'NO' ? micOnIcon : micOffIcon}
                      alt={intl.formatMessage({ id: 'participants.mic' })}
                    />
                  </Button>
                </div>
              </div>
              <Button
                className="participant-disconnect-btn"
                onClick={this.disconnectParticipant}
              >
                <img
                  className="remove"
                  src={removeIcon}
                  alt={intl.formatMessage({ id: 'participants.remove' })}
                />
              </Button>
            </div>

          </li>
        ) : null }

      </>
    );
  }
}

ParticipantsItem.propTypes = {
  intl: PropTypes.shape().isRequired,
  participant: PropTypes.shape().isRequired,
};

export default injectIntl(ParticipantsItem);
