import { SET_SUBTITLE } from "constants/ActionTypes";

const defaultState = {
  langId: null,
};

const settings = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SUBTITLE: {
      return state.langId === action.langId ? state : { ...state, langId: action.langId };
    }
    default:
      return state;
  }
};

export default settings;
