import {
  PATIENT_CALL_REQUEST,
  PATIENT_CALL_SUCCESS,
  CLINICAL_NOTIFICATION_REQUEST,
} from '@constants/UHEActionTypes';

/**
 * patientCall Action Creator
 * @param {object} payload Body Data
 * @returns {object} patientCall Data
 */
export const patientCall = (payload) => ({
  type: PATIENT_CALL_REQUEST,
  payload,
});

/**
 * patientCallSuccess Action Creator
 * @param {object} payload Data
 * @returns {void} patientCallSuccess Data
 */
export const patientCallSuccess = (payload) => ({
  type: PATIENT_CALL_SUCCESS,
  payload,
});

/**
 * clinicalNotification Action Creator
 * @returns {void} clinicalNotification Data
 */
export const clinicalNotification = () => ({
  type: CLINICAL_NOTIFICATION_REQUEST,
});
