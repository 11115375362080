import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import RestManager from '@util/RestManager';
import { ENDPOINTS, BASE_MOB } from '@constants/UHEEndpoints';
import { fetchError, showMessage, showWarning } from '@actions/Common';

import { PATIENT_CALL_REQUEST, CLINICAL_NOTIFICATION_REQUEST } from '@constants/UHEActionTypes';
import { sendApsCommandRequest } from '@uhe_sagas/GuestSaga';
import { patientCallSuccess } from '@uhe_actions/ClinicianActions';
import { redirectToApsOptions, redirectToMainPage } from '@actions/uhe/GuestActions';

/**
 * Sends POST Request to api/patient-call
 * @param {object} body Body Data
 * @returns {object} Data
 */
const patientCallRequest = async (body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.clinician.patientCall}`, 'POST', body, [], true, true, BASE_MOB);

  return request;
};

/**
 * Sends POST Request to /endpoints/:id/command/alert
 * @param {string} id Device ID
 * @param {object} body Request Body
 * @returns {Promise<*|Response|void>} Response
 */
const clinicalNotificationRequest = async (id, body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.clinician.endpoints}/${id}/command/alert`, 'POST', body);

  return request;
};

/**
 * clinicalNotificationSaga Saga Worker
 * @returns {void}
 */
function* clinicalNotificationSaga() {
  const id = sessionStorage.getItem('aps_id');
  const response = yield call(clinicalNotificationRequest, id, 'alert');

  if (response) {
    yield put(showMessage('clinicalNotification'));
  }
}

/**
 * patientCallSaga Saga Worker
 * @param {object} data Body Data
 * @returns {void}
 */
function* patientCallSaga(data) {
  try {
    const response = yield call(patientCallRequest, data.payload);

    if (response) {
      yield put(redirectToMainPage(false));
      const apsBody = {
        unique_id: sessionStorage.getItem('aps_id'),
        command: 'api',
        params_p: {
          vmr: response.room.conference.aliases[0].alias,
          announce: true,
        },
      };
      const responseAps = yield call(sendApsCommandRequest, apsBody);
      yield put(patientCallSuccess({ ...response }));

      if (responseAps.busy_status === 'open' && responseAps.success === 'true') {
        yield put(showMessage('inviteSuccess'));
        yield put(redirectToApsOptions(true));
      } else if (responseAps.busy_status === 'busy' && responseAps.success === 'false') {
        yield put(showWarning('apsBusy'));
        yield put(redirectToApsOptions(false));
      } else {
        yield put(showWarning('apsIsOffline'));
        yield put(redirectToApsOptions(false));
      }
    } else {
      yield put(redirectToMainPage(true));
      yield put(showWarning('callFail'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sagas Watcher
 * @returns {void}
 */
export function* sagasWatcher() {
  yield takeEvery(PATIENT_CALL_REQUEST, patientCallSaga);
  yield takeEvery(CLINICAL_NOTIFICATION_REQUEST, clinicalNotificationSaga);
}

/**
 * Performs Non-Blocking Call
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(sagasWatcher)]);
}
