import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import zoomInIcon from '@root/assets/images/device-control-page/care_device_zoom_in.svg';
import zoomOutIcon from '@root/assets/images/device-control-page/care_device_zoom_out.svg';
import controlIcon from '@root/assets/images/device-control-page/care_device_controller.svg';
import homeIcon from '@root/assets/images/device-control-page/care_device_home.svg';
import careIcon from '@root/assets/images/device-control-page/care_device_pin2.svg';
import IntlMessages from '@util/IntlMessages';
import controlCommand from '@util/ControlCommands';
import { connect } from 'react-redux';
import {
  getZoom, getStop, getPan, goToPin,
} from '@uhe_actions/DeviceControlActions';
import {
  ZOOM_IN, ZOOM_OUT, CONTROL_DOWN, CONTROL_DOWNLEFT,
  CONTROL_DOWNRIGHT, CONTROL_RIGHT, CONTROL_UP, CONTROL_UPLEFT,
  CONTROL_UPRIGHT, CONTROL_LEFT,
} from '@constants/UHEActionTypes';
import { Button } from 'antd';

/**
 * Control Class Component
 * @returns {void}
 */
class Control extends PureComponent {
  /**
   * Control Page Constructor
   * @param {object} props Control Page Props
   */
  constructor(props) {
    super(props);
    this.state = {
      isMouseDownSent: false,
    };
  }

  /**
 * Handles Control Direction feature
 * @param {string} direction current direction
 * @returns {function} command function
 */
  handleDirection = (direction) => {
    const { getPan, uniqueID } = this.props;
    const panCommand = controlCommand(uniqueID, 'pan', { dir: direction });
    getPan({ panCommand });
    this.setState({ isMouseDownSent: true });
  }

  /**
 * Handles Stop Motion feature
 * @param {string} type type of command
 * @returns {function} command function
 */
  handleStop = (type) => {
    const { isMouseDownSent } = this.state;
    const { getStop, uniqueID } = this.props;
    const stopCommand = controlCommand(uniqueID, type, { dir: 'stop' });
    setTimeout(() => {
      if (isMouseDownSent) {
        getStop({ stopCommand });
      }
    }, 300);
    this.setState({ isMouseDownSent: false });
  }

  /**
 * Handles Zoom Feature
 * @param {string} type the type of zoom
 * @returns {function} command function
 */
  handleZoom = (type) => {
    const { getZoom, uniqueID } = this.props;
    const zoomCommand = controlCommand(uniqueID, 'zoom', { dir: type });
    getZoom({ zoomCommand });
    this.setState({ isMouseDownSent: true });
  }

  /**
   * handleGoToPin
   * @param {string} unique_id pin id
   * @returns {void}
   */
    handleGoToHome = () => {
      const { uniqueID, goToPinAction } = this.props;
      const goToPinCommand = controlCommand(uniqueID, 'go_to_bookmark', { bookmark: 'home' });
      goToPinAction({ goToPinCommand });
    }

    /**
   * Renders Control Component
   * @returns {JSX.Element} Control Component
   */
    render() {
      const {
        intl, toPins, editPin,
      } = this.props;

      return (
        <>
          {editPin && <h1 className="editing-msg"><IntlMessages id="device-control.editingMessage" /></h1>}
          <div className="control-wrapper" onTouchEnd={() => this.handleStop('pan')}>
            <img src={controlIcon} className="control-icon" alt={intl.formatMessage({ id: 'device-control.controlIcon' })} />
            <img src={homeIcon} className="control-home-icon" alt={intl.formatMessage({ id: 'device-control.homeIcon' })} onClick={this.handleGoToHome} />
            <div
              className="up-btn"
              onTouchStart={() => this.handleDirection(CONTROL_UP)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_UP)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="down-btn"
              onTouchStart={() => this.handleDirection(CONTROL_DOWN)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_DOWN)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="right-btn"
              onTouchStart={() => this.handleDirection(CONTROL_RIGHT)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_RIGHT)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="left-btn"
              onTouchStart={() => this.handleDirection(CONTROL_LEFT)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_LEFT)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="upper-right-btn"
              onTouchStart={() => this.handleDirection(CONTROL_UPRIGHT)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_UPRIGHT)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="down-right-btn"
              onTouchStart={() => this.handleDirection(CONTROL_DOWNRIGHT)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_DOWNRIGHT)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="upper-left-btn"
              onTouchStart={() => this.handleDirection(CONTROL_UPLEFT)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_UPLEFT)}
              onMouseUp={() => this.handleStop('pan')}
            />
            <div
              className="down-left-btn"
              onTouchStart={() => this.handleDirection(CONTROL_DOWNLEFT)}
              onTouchEnd={() => this.handleStop('pan')}
              onMouseDown={() => this.handleDirection(CONTROL_DOWNLEFT)}
              onMouseUp={() => this.handleStop('pan')}
            />
          </div>
          <div className="control-zoom-icons-wrapper">
            <Button
              className="control-zoom-icons"
              type="primary"
              onTouchStart={() => this.handleZoom(ZOOM_OUT)}
              onTouchEnd={() => this.handleStop('zoom')}
              onMouseDown={() => this.handleZoom(ZOOM_OUT)}
              onMouseUp={() => this.handleStop('zoom')}
            >
              -
            </Button>
            <Button
              className="control-zoom-icons"
              type="primary"
              onTouchStart={() => this.handleZoom(ZOOM_IN)}
              onTouchEnd={() => this.handleStop('zoom')}
              onMouseDown={() => this.handleZoom(ZOOM_IN)}
              onMouseUp={() => this.handleStop('zoom')}
            >
              +
            </Button>
          </div>
          <div className="pin-location-wrapper" onClick={() => toPins()}>
            <img src={careIcon} alt={intl.formatMessage({ id: 'device-control.pinLocation' })} />
            <p className="pin-location-p"><IntlMessages id="device-control.pinLocation" /></p>
          </div>
          <div className="refresh-reboot-wrapper">
            <p><IntlMessages id="device-control.refresh" /></p>
            <p><IntlMessages id="device-control.reboot" /></p>
          </div>
        </>
      );
    }
}

Control.propTypes = {
  intl: PropTypes.shape().isRequired,
};

/**
 * Maps State to Props
 * @param {string} guest destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ endpoints }) => ({
  uniqueID: endpoints.endpoint.unique_id,
});
/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getZoom: (payload) => dispatch(getZoom(payload)),
  getStop: (payload) => dispatch(getStop(payload)),
  getPan: (payload) => dispatch(getPan(payload)),
  goToPinAction: (payload) => dispatch(goToPin(payload)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Control));
