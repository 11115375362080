import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import IntlMessages from '@util/IntlMessages';
import { Radio, Button } from 'antd';
import { CONTROL_VALUE, PINS_VALUE, SETTINGS_VALUE } from '@constants/UHESettings';
import closeIcon from '@root/assets/images/invite_details_arrow_closed_over.svg';
import { withRouter } from 'react-router-dom';
import Control from '@components/aps/components/Control';
import Pins from '@components/aps/components/Pins';
import Settings from '@components/aps/components/Settings';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPins } from '@uhe_actions/DeviceControlActions';

/**
 * Device Control Page Class Component
 * @returns {void}
 */
class DeviceControlPage extends Component {
  /**
   * Device Control Page Constructor
   * @param {object} props Device Control Page Props
   */
  constructor(props) {
    super(props);
    this.state = {
      type: CONTROL_VALUE,
      id: '',
      editPin: '',
    };
  }

  /**
   * Handles Tabs
   * @returns {void}
   */
  componentDidMount() {
    const { tab, getPinsAction, customerData } = this.props;
    this.setState({ type: tab, id: customerData.unique_id });
    getPinsAction(customerData);
  }

  /**
   * Handles changeToPins
   * @returns {void}
   */
  changeToPins = () => {
    this.setState({ type: PINS_VALUE });
  }

  /**
   * Handles changeToControl
   * @param {object} pinName bookmark data
   * @returns {void}
   */
  changeToControl = (pinName) => {
    this.setState({ type: CONTROL_VALUE, editPin: pinName });
  }

  resetEditPin = () => {
    this.setState({ editPin: '' });
  }

  /**
   * Device Control Page Tabs
   * @returns {object} component
   */
  getComponent = () => {
    const { type, id, editPin } = this.state;
    const { roomPins } = this.props;
    switch (type) {
      case CONTROL_VALUE:
        return <Control toPins={this.changeToPins} editPin={editPin} />;
      case PINS_VALUE:
        return (
          <Pins
            roomPins={roomPins}
            id={id}
            toControl={this.changeToControl}
            editPin={editPin}
            resetEditPin={this.resetEditPin}
          />
        );
      case SETTINGS_VALUE:
        return <Settings />;
      default:
        return type;
    }
  }

  /**
   * Handles close button
   * @returns {void}
   */
     redirectOnClose = () => {
       const { history: { push } } = this.props;
       push('/aps-options');
     }

     /**
   * Renders Device Control Page Component
   * @returns {JSX.Element} Device Control Page Component
   */
     render() {
       const { intl } = this.props;
       const { type } = this.state;
       return (
         <div className="gx-d-flex gx-flex-column gx-align-items-center gx-mt-5 device-control-container">
           <div className="headline-flex">
             <Button
               className="chat-back-btn"
               size="small"
               type="text"
               icon={<img src={closeIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-back-img" />}
               onClick={this.redirectOnClose}
             />
             <h1 className="headline">
               <IntlMessages id="device-control.title" />
             </h1>
             <div />
           </div>
           <Radio.Group
             size="large"
             buttonStyle="solid"
             onChange={(event) => this.setState({ type: event.target.value })}
             className="device-control-radio-group-wrapper"
             value={type}
           >
             <Radio.Button value={CONTROL_VALUE} className="device-control-radio-btn">
               <IntlMessages id="device-control.control" />
             </Radio.Button>
             <Radio.Button value={PINS_VALUE} className="device-control-radio-btn">
               <IntlMessages id="device-control.pins" />
             </Radio.Button>
             <Radio.Button value={SETTINGS_VALUE} className="device-control-radio-btn">
               <IntlMessages id="device-control.settings" />
             </Radio.Button>
           </Radio.Group>
           {this.getComponent()}
         </div>
       );
     }
}

DeviceControlPage.propTypes = {
  intl: PropTypes.shape().isRequired,
};

/**
 * Maps State to Props
 * @param {string} deviceControl destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ deviceControl, endpoints }) => ({
  tab: deviceControl.tab,
  roomPins: deviceControl.pins,
  customerData: endpoints.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
  getPinsAction: (payload) => dispatch(getPins(payload)),
});

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceControlPage)),
);
