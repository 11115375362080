import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import configureStore, { history } from './appRedux/store';
import './firebase/firebase';
import App from './containers/App/index';

export const store = configureStore(/* provide initial state if any */);

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <LastLocationProvider>
          <Route path="/" component={App} />
        </LastLocationProvider>
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
