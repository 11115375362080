import { PEXIP_UPDATE_PARTICIPANT, PEXIP_REMOVE_PARTICIPANT, PEXIP_CHAT_MESSAGE } from '@constants/UHEActionTypes';

/**
 * updatePaticipant Action Creator
 * @param {object} payload Participant Data
 * @returns {object} Type && Participant Data
 */
export const updatePaticipant = (payload) => ({
  type: PEXIP_UPDATE_PARTICIPANT,
  payload,
});

/**
 * removeParticipant Action Creator
 * @param {object} payload Participant Data
 * @returns {object} Type && Participant Data
 */
export const removeParticipant = (payload) => ({
  type: PEXIP_REMOVE_PARTICIPANT,
  payload,
});

/**
 * chatMessage Action Creator
 * @param {object} payload message content
 * @returns {object} Type && Message Content
 */
export const chatMessage = (payload) => ({
  type: PEXIP_CHAT_MESSAGE,
  payload,
});
