import {
  TAB_CONTROL, TAB_SETTINGS, GET_PINS_SUCCESS, MIC_STATUS, CAM_STATUS, SELF_VIEW_STATUS,
} from '@constants/UHEActionTypes';
import { CONTROL_VALUE, SETTINGS_VALUE } from '@constants/UHESettings';

const INIT_STATE = {
  tab: CONTROL_VALUE,
  pins: [],
  camStatus: true,
  micStatus: true,
  selfViewStatus: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TAB_CONTROL:
      return { ...state, tab: CONTROL_VALUE };
    case TAB_SETTINGS:
      return { ...state, tab: SETTINGS_VALUE };
    case GET_PINS_SUCCESS:
      return { ...state, pins: action.payload };
    case MIC_STATUS:
      return { ...state, micStatus: action.payload };
    case CAM_STATUS:
      return { ...state, camStatus: action.payload };
    case SELF_VIEW_STATUS:
      return { ...state, selfViewStatus: action.payload };
    default:
      return state;
  }
};
