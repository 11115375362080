import {
  Col, Divider, Row, Slider,
} from 'antd';
import React, { PureComponent } from 'react';
import IntlMessages from '@util/IntlMessages';
import speakerIcon from '@root/assets/images/device-control-page/settings_room_device_speaker.svg';
import micIcon from '@root/assets/images/device-control-page/settings_room_device_mic.svg';
import bellIcon from '@root/assets/images/device-control-page/settings_room_device_doorbell.svg';
import brightnessIcon from '@root/assets/images/device-control-page/settings_room_device_brightness.svg';
import speedIcon from '@root/assets/images/device-control-page/settings_room_device_speed.svg';
import focusIcon from '@root/assets/images/device-control-page/settings_room_device_focus.svg';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import controlCommand from '@util/ControlCommands';
import { connect } from 'react-redux';
import {
  getSpeaker, getMic, getBright, getSpeed, getFocus, getDoorbell,
} from '@uhe_actions/DeviceControlActions';
import {
  SPEAKER_VALUE, MIC_VALUE, DOORBELL_VALUE, BRIGHTNESS_VALUE, SPEED_VALUE, FOCUS_VALUE,
} from '@constants/UHESettings';

/**
 * Device Settings Class Component
 * @returns {void}
 */
class Settings extends PureComponent {
  /**
   * Handles change
   * @param {number} value value
   * @param {string} type value type
   * @return {void}
   */
  handleChange = (value, type) => {
    const {
      getSpeaker, uniqueID, getMic, getBright, getSpeed, getFocus, getDoorbell,
    } = this.props;
    const speakerCommand = controlCommand(uniqueID, 'set_speaker', { mute: value, point: value });
    const micCommand = controlCommand(uniqueID, 'set_microphone', { mute: value, point: value });
    const brightnessCommand = controlCommand(uniqueID, 'adj_brightness', { bright: value });
    const speedCommand = controlCommand(uniqueID, 'set_speed', { speed: value });
    const focusCommand = controlCommand(uniqueID, 'set_focus', { auto: value, point: value });
    const doorbellCommand = controlCommand(uniqueID, 'adjust_bell', { bell_volume: value });
    switch (type) {
      case SPEAKER_VALUE:
        return getSpeaker({ speakerCommand });
      case MIC_VALUE:
        return getMic({ micCommand });
      case BRIGHTNESS_VALUE:
        return getBright({ brightnessCommand });
      case SPEED_VALUE:
        return getSpeed({ speedCommand });
      case FOCUS_VALUE:
        return getFocus({ focusCommand });
      case DOORBELL_VALUE:
        return getDoorbell({ doorbellCommand });
      default:
        return null;
    }
  }

  /**
   * Renders Settings Component
   * @returns {JSX.Element} Settings Component
   */
  render() {
    const { intl } = this.props;
    return (
      <>
        <Row className="settings-grid">
          <Col span={24}>
            <h5 className="settings-title">
              <IntlMessages id="device-control.audio" />
            </h5>
          </Col>
          <Col span={8} className="settings-grid-item">
            <Slider vertical max="10" defaultValue={3} onChange={(value) => this.handleChange(value, SPEAKER_VALUE)} />
            <div>
              <img src={speakerIcon} className="settings-icons" alt={intl.formatMessage({ id: 'device-control.speaker' })} />
              <p className="settings-subtitle">
                <IntlMessages id="device-control.speaker" />
              </p>
            </div>
          </Col>
          <Col span={8} className="settings-grid-item">
            <Slider vertical max="10" defaultValue={3} onChange={(value) => this.handleChange(value, MIC_VALUE)} />
            <div>
              <img src={micIcon} className="settings-icons" alt={intl.formatMessage({ id: 'device-control.mic' })} />
              <p className="settings-subtitle">
                <IntlMessages id="device-control.mic" />
              </p>
            </div>
          </Col>
          <Col span={8} className="settings-grid-item">
            <Slider vertical max="1" step={0.1} defaultValue={0.3} onChange={(value) => this.handleChange(value, DOORBELL_VALUE)} />
            <div>
              <img src={bellIcon} className="settings-icons" alt={intl.formatMessage({ id: 'device-control.doorbell' })} />
              <p className="settings-subtitle">
                <IntlMessages id="device-control.doorbell" />
              </p>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={12} className="settings-divider-wrapper">
            <Divider className="settings-divider" />
          </Col>
        </Row>
        <Row className="settings-grid">
          <Col span={24}>
            <h5 className="settings-title">
              <IntlMessages id="device-control.video" />
            </h5>
          </Col>
          <Col span={8} className="settings-grid-item">
            <Slider vertical max="20" defaultValue={3} onChange={(value) => this.handleChange(value, BRIGHTNESS_VALUE)} />
            <div>
              <img src={brightnessIcon} className="settings-icons" alt={intl.formatMessage({ id: 'device-control.brightness' })} />
              <p className="settings-subtitle">
                <IntlMessages id="device-control.brightness" />
              </p>
            </div>
          </Col>
          <Col span={8} className="settings-grid-item">
            <Slider vertical max="20" defaultValue={3} onChange={(value) => this.handleChange(value, SPEED_VALUE)} />
            <div>
              <img src={speedIcon} className="settings-icons settings-speed-icon" alt={intl.formatMessage({ id: 'device-control.speed' })} />
              <p className="settings-subtitle">
                <IntlMessages id="device-control.speed" />
              </p>
            </div>
          </Col>
          <Col span={8} className="settings-grid-item">
            <Slider vertical max="20" defaultValue={3} onChange={(value) => this.handleChange(value, FOCUS_VALUE)} />
            <div>
              <img src={focusIcon} className="settings-icons" alt={intl.formatMessage({ id: 'device-control.focus' })} />
              <p className="settings-subtitle">
                <IntlMessages id="device-control.focus" />
              </p>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

Settings.propTypes = {
  intl: PropTypes.shape().isRequired,
};

/**
 * Maps State to Props
 * @param {string} guest destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ endpoints }) => ({
  uniqueID: endpoints.endpoint.unique_id,
});
/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getSpeaker: (payload) => dispatch(getSpeaker(payload)),
  getMic: (payload) => dispatch(getMic(payload)),
  getBright: (payload) => dispatch(getBright(payload)),
  getSpeed: (payload) => dispatch(getSpeed(payload)),
  getFocus: (payload) => dispatch(getFocus(payload)),
  getDoorbell: (payload) => dispatch(getDoorbell(payload)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings));
