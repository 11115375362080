import { ENDPOINTS_REQUEST, ENDPOINTS_SUCCESS } from '@constants/UHEActionTypes';

const INIT_STATE = {
  endpoint: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ENDPOINTS_REQUEST:
      return { ...state };

    case ENDPOINTS_SUCCESS:
      return { ...state, endpoint: { ...action.payload } };

    default:
      return state;
  }
};
