import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import IntlMessages from '@util/IntlMessages';
import { injectIntl } from 'react-intl';

import ApsFooter from '@components/aps/UI/ApsFooter';
import CircularProgress from 'components/CircularProgress';
import { patientCall, clinicalNotification } from '@uhe_actions/ClinicianActions';

import inviteCliniciansIcon from '@root/assets/images/main-page/invite_clinicians.svg';
import inviteGuestsIcon from '@root/assets/images/main-page/invite_guests.svg';
import inviteInterpretersIcon from '@root/assets/images/main-page/invite_interpreters.svg';
import watchMovieIcon from '@root/assets/images/main-page/watch_movie.svg';
import caregilityLogo from '@root/assets/images/c-logo.png';
import { getAccessToken } from '@util/UheHelper';
import { ACCESS_TOKEN_KEY } from '@constants/UHESettings';
import { endpointsRequest } from '@uhe_actions/EndpointsActions';

/**
 * MainPage Component
 */
class MainPage extends Component {
  /**
   * MainPage Constructor
   * @param {object} props MainPage Props
   */
  constructor(props) {
    super(props);
    const { location } = this.props;

    this.state = {
      availablePermissions: [],
    };

    this.qParams = new URLSearchParams(location.search);
    this.accessToken = this.qParams.get('token');
    this.apsId = this.qParams.get('aps_id');
    this.availableFunctions = this.qParams.get('available_functions');
    this.currentState = this.qParams.get('state');
    this.lastVmr = this.qParams.get('vmr');
  }

  /**
   * vomponentDidMount
   * @returns {void}
   */
  componentDidMount() {
    const { endpointsRequestAction, history: { push } } = this.props;
    if (this.lastVmr) {
      sessionStorage.setItem('vmr', this.lastVmr);
    }
    if (this.availableFunctions) {
      sessionStorage.setItem('available_functions', this.availableFunctions);
    }
    if (this.accessToken && this.apsId) {
      sessionStorage.setItem(ACCESS_TOKEN_KEY, this.accessToken);
      sessionStorage.setItem('aps_id', this.apsId);
    }

    if (getAccessToken()) {
      endpointsRequestAction(sessionStorage.getItem('aps_id'));
    }

    switch (this.currentState) {
      case 'entertainment':
        push('/entertainment');
        break;
      case 'clinician_call':
        push('/aps-options');
        break;
      default:
        push('/');
    }
    if (sessionStorage.getItem('available_functions')) {
      const currentPermissions = sessionStorage.getItem('available_functions').split(',');
      this.setState({ availablePermissions: currentPermissions });
    }
  }

  /**
   * Hadles Subscriptions Update
   * @returns {void}
   */
  componentDidUpdate() {
    const { history, redirectToApsOptionsPage } = this.props;

    if (redirectToApsOptionsPage === true) {
      history.replace('/aps-options');
    }
  }

  /**
   * Clear Session Storage
   * @returns {void}
   */
  logoutHandler = () => {
    const { history } = this.props;

    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('roomNumber');
    sessionStorage.removeItem('aps_id');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');

    history.replace('/');
  }

  /**
   * Sends Notification to the Clinician
   * @returns {void}
   */
  sendClinicianNotification = () => {
    const { patientCallAction, endpoint } = this.props;
    const body = {
      organization: endpoint.organization,
      customer: endpoint.customer,
      facility: endpoint.facility,
      unit: endpoint.unit,
    };

    patientCallAction(body);
  }

  /**
   * Renders MainPage Component
   * @returns {JSX.Element} MainPage Component
   */
  render() {
    const {
      intl, loading, redirectToMainPage, clinicalNotificationAction,
    } = this.props;
    const { availablePermissions } = this.state;

    return (
      <div className="aps-layout">
        <section className="mainpage">
          <div className="gx-mb-2 mainpage_head">
            <img src={caregilityLogo} alt={intl.formatMessage({ id: 'common.caregilityLogo' })} />
            <Button className="logout" onClick={this.logoutHandler}>
              <IntlMessages id="mainPage.logout" />
            </Button>
          </div>
          {(loading && !redirectToMainPage) ? <CircularProgress /> : (
            <div className="mainpage_body">
              <Row gutter={(8, 8)}>
                <Col span={12} className="link">
                  {availablePermissions.some((item) => item === 'call_clinician')
                  && (
                  <div
                    className="clinician-btn"
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                    onClick={() => clinicalNotificationAction()}
                  >
                    <img
                      src={inviteCliniciansIcon}
                      alt={intl.formatMessage({ id: 'mainPage.clinician' })}
                    />
                    <span>
                      <IntlMessages id="mainPage.meetWithA" />
                    </span>
                    <p>
                      <IntlMessages id="mainPage.clinician" />
                    </p>
                  </div>
                  )}
                </Col>
                <Col span={12} className="link">
                  {availablePermissions.some((item) => item === 'call_guest')
                  && (
                  <Link to="/guest">
                    <img
                      src={inviteGuestsIcon}
                      alt={intl.formatMessage({ id: 'mainPage.guest' })}
                    />
                    <span>
                      <IntlMessages id="mainPage.meetWithA" />
                    </span>
                    <p>
                      <IntlMessages id="mainPage.guest" />
                    </p>
                  </Link>
                  )}
                </Col>
              </Row>
              <Row gutter={(8, 8)}>
                <Col span={12} className="link">
                  {availablePermissions.some((item) => item === 'call_interpreter')
                  && (
                  <Link to="/interpreter">
                    <img
                      src={inviteInterpretersIcon}
                      alt={intl.formatMessage({ id: 'mainPage.interpreter' })}
                    />
                    <span>
                      <IntlMessages id="mainPage.meetWithAn" />
                    </span>
                    <p>
                      <IntlMessages id="mainPage.interpreter" />
                    </p>
                  </Link>
                  )}
                </Col>
                <Col span={12} className="link">
                  {availablePermissions.some((item) => item === 'entertainment')
                  && (
                  <Link to="/entertainment">
                    <img
                      src={watchMovieIcon}
                      alt={intl.formatMessage({ id: 'mainPage.showMovie' })}
                    />
                    <span>
                      <IntlMessages id="mainPage.watchA" />
                    </span>
                    <p>
                      <IntlMessages id="mainPage.showMovie" />
                    </p>
                  </Link>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </section>
        <ApsFooter />
      </div>
    );
  }
}

MainPage.propTypes = {
  endpointsRequestAction: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  patientCallAction: PropTypes.func.isRequired,
  redirectToApsOptionsPage: PropTypes.bool.isRequired,
  redirectToMainPage: PropTypes.bool.isRequired,
  endpoint: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  clinicalNotificationAction: PropTypes.func.isRequired,
};

/**
 * Maps Global State to Props
 * @param {object} guest Guest Global State
 * @returns {object} Data from Global State
 */
const mapStateToProps = ({ clinician, guest, endpoints }) => ({
  loading: clinician.loading,
  redirectToApsOptionsPage: guest.redirectToApsOptionsPage,
  endpoint: endpoints.endpoint,
  redirectToMainPage: guest.redirectToMainPage,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  patientCallAction: (payload) => dispatch(patientCall(payload)),
  clinicalNotificationAction: () => dispatch(clinicalNotification()),
  endpointsRequestAction: (payload) => dispatch(endpointsRequest(payload)),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage)));
