import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = (props) => (
  <Scrollbars
    {...props}
    autoHide
    renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
  />
);

export default CustomScrollbars;
