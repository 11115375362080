/**
 * controlCommand function
 * @param {string} unique_id room unique id
 * @param {string} command command type
 * @param {object} params_p params for the command
 * @returns {void}
 */
const controlCommand = (unique_id, command, params_p) => {
  const commandBody = {
    unique_id,
    command,
    params_p,
  };
  return commandBody;
};

export default controlCommand;
