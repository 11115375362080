import React, { PureComponent } from 'react';
import IntlMessages from '@util/IntlMessages';
import {
  Button, Divider, Select, Radio,
} from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { getLanguages, getVmr, inviteInterpreter } from '@root/appRedux/actions/uhe/InterpreterActions';
import {
  ANY, FEMALE, MALE, REQUEST_TYPE,
} from '@constants/UHESettings';
import closeIcon from '@root/assets/images/invite_details_arrow_closed_over.svg';
import PropTypes from 'prop-types';

const { Option } = Select;
/**
 * InterpreterPage
 * @param {string} language current language
 * @returns {void}
 */
class InterpreterPage extends PureComponent {
  /**
   * InterpreterPage Constructor
   * @param {object} props GuestPage Props
   */
  constructor(props) {
    super(props);

    this.state = {
      voice: ANY,
      toDestination: '',
      customer: '',
      name: '',
      language: '',
      currentLanguageObject: {},
      showRadio: false,
    };
  }

  /**
   * Handles Subscriptions
   * @returns {void}
   */
  componentDidMount() {
    const { getVmr, getLanguages, profile } = this.props;
    const { firstName, lastName } = profile;
    const customer = sessionStorage.getItem('customer');
    getVmr();
    getLanguages(profile.customer);
    this.setState({ customer, name: `${firstName} ${lastName}` });
  }

  /**
   * Handles voice update
   * @param {string} prevProps props
   * @param {string} prevState state
   * @returns {void}
   */
  componentDidUpdate(prevProps, prevState) {
    const { voice } = this.state;
    if (prevState.voice !== voice) {
      const { currentLanguageObject } = this.state;
      const { endpoints } = currentLanguageObject[0];
      const filterVoices = endpoints.filter((item) => item.voice === voice);
      const { destination } = filterVoices[0];
      this.setState({ toDestination: destination });
    }
    const { history, redirectToApsOptionsPage } = this.props;
    if (redirectToApsOptionsPage === true) {
      history.replace('/aps-options');
    }
  }

  /**
   * Handles invite
   * @returns {void}
   */
  sendInvite = () => {
    const {
      customer, name, language, currentLanguageObject, toDestination,
    } = this.state;
    const { inviteInterpreter, vmr } = this.props;
    const inviteBody = {
      customer,
      name,
      destination: language,
      vmrAlias: vmr.conference.aliases[0].alias,
    };
    const inviteBodyPexip = {
      to: toDestination,
      body: '',
      vmr: vmr.conference.aliases[0].alias,
    };
    const apsBody = {
      unique_id: sessionStorage.getItem('aps_id'),
      command: 'api',
      params_p: {
        vmr: vmr.conference.aliases[0].alias,
        announce: true,
      },
    };
    if (language) {
      const { endpoints } = currentLanguageObject[0];
      const { type } = endpoints[0];
      if (type === REQUEST_TYPE) {
        return inviteInterpreter({ inviteBody, apsBody });
      }
      return inviteInterpreter({ inviteBodyPexip, apsBody });
    }
  }

  /**
   * Handles language update
   * @param {string} language selected language
   * @returns {void}
   */
  handleChange = (language) => {
    const { languages } = this.props;
    const { voice } = this.state;
    const currentLanguageObject = languages.filter((item) => item.name === language);
    this.setState({ language, currentLanguageObject });
    const { endpoints } = currentLanguageObject[0];
    const { type } = endpoints[0];
    const filterVoices = endpoints.filter((item) => item.voice === voice);
    const { destination } = filterVoices[0];
    this.setState({ toDestination: destination });
    if (type !== REQUEST_TYPE) {
      this.setState({ showRadio: true });
    } else {
      this.setState({ showRadio: false });
    }
  }

  /**
   * Handles close button
   * @returns {void}
   */
  redirectOnClose = () => {
    const { history: { push } } = this.props;
    push('/main');
  }

  /**
   * Renders InterpreterPage Component
   * @returns {JSX.Element} InterpreterPage Component
   */
  render() {
    const { languages, intl } = this.props;
    const { showRadio } = this.state;
    return (
      <>
        <div>
          <div className="headline-flex">
            <Button
              className="chat-back-btn"
              size="small"
              type="text"
              icon={<img src={closeIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-back-img" />}
              onClick={this.redirectOnClose}
            />
            <h1 className="headline">
              <IntlMessages id="interpreter.inviteInterpreter" />
            </h1>
            <div />
          </div>
          <Divider className="chat-divider" />
          <div className="select-wrapper">

            <Select
              className="select"
              showSearch
              placeholder={<IntlMessages id="interpreter.language" />}
              onChange={this.handleChange}
            >
              {languages.map((language, index) => (
                <Option key={index} value={language.name}>{language.name}</Option>
              ))}
            </Select>
          </div>
          {showRadio
          && (
          <Radio.Group
            className="interpreter-radio-group"
            defaultValue={ANY}
            onChange={(event) => this.setState({ voice: event.target.value })}
          >
            <Radio value={ANY}><IntlMessages id="interpreter.anyVoice" /></Radio>
            <Radio value={MALE}><IntlMessages id="interpreter.maleVoice" /></Radio>
            <Radio value={FEMALE}><IntlMessages id="interpreter.femaleVoice" /></Radio>
          </Radio.Group>
          )}
          <div className="invite-btn-wrapper">
            <Button className="invite-btn" htmlType="submit" onClick={this.sendInvite}>
              <IntlMessages id="guest.invite" />
            </Button>
          </div>
        </div>
      </>
    );
  }
}

InterpreterPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  redirectToApsOptionsPage: PropTypes.bool,
};
/**
 * Maps State to Props
 * @param {string} guest destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ interpreter, endpoints, guest }) => ({
  vmr: interpreter.vmr,
  languages: interpreter.languages,
  profile: endpoints.endpoint,
  redirectToApsOptionsPage: guest.redirectToApsOptionsPage,
});
/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getVmr: () => dispatch(getVmr()),
  inviteInterpreter: (payload) => dispatch(inviteInterpreter(payload)),
  getLanguages: (payload) => dispatch(getLanguages(payload)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(injectIntl(withRouter(InterpreterPage)));
