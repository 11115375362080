class HTTPResponseError extends Error {
  constructor(message, code) {
    super(message);
    this.message = message;
    this.code = code;
    this.name = 'HTTPResponseError';
  }
}

class HTTPResponseWarning extends Error {
  constructor(message, code) {
    super(message);
    this.message = message;
    this.code = code;
    this.name = 'HTTPResponseWarning';
  }
}

class ServerError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.code = 500;
    this.name = 'ServerError';
  }
}

export {
  HTTPResponseError,
  HTTPResponseWarning,
  ServerError,
}
