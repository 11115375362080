import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import { onFetchOwnPofileSuccess } from '@actions/uhe/configuration/users/UsersActions';
import {
  FETCH_OWN_PROFILE_REQUEST,
} from '@constants/UHEActionTypes';
import { ENDPOINTS } from '@constants/UHEEndpoints';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * Sends GET Request
 * @returns {void}
 */
const doFetchOwnProfile = async () => await RestManager.request(`${ENDPOINTS.users.ownProfile}`);

/**
 * Fetching own user profile
 * @returns {void}
 */
function* fetchOwnProfile() {
  try {
    const profile = yield call(doFetchOwnProfile);
    if (profile) {
      yield put(onFetchOwnPofileSuccess({ ...profile }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_OWN_PROFILE_REQUEST, fetchOwnProfile);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
