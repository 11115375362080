import React from 'react';
import IntlMessages from '@util/IntlMessages';
import { footerText } from 'util/config';
import { Layout } from 'antd';

const { Footer } = Layout;

/**
 * ApsFooter Component
 * @returns {void}
 */
const ApsFooter = () => (
  <Footer>
    <div className="gx-layout-footer-content">
      <ul className="footer-nav">
        <li>
          <a href="#">
            <IntlMessages id="mainPage.support" />
          </a>
        </li>
        <li>
          <a href="#">
            <IntlMessages id="mainPage.privacyPolicy" />
          </a>
        </li>
      </ul>
      {footerText}
    </div>
  </Footer>

);

export default ApsFooter;
