import {
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
} from '@constants/UHEActionTypes';

import * as AdminRoles from '@constants/AdminRoles';
import { CaseHelper } from 'util/UheHelper';

const INIT_STATE = {
  ownUser: {
    loading: true,
  },
  loading: false,
};

/**
 * @description USERS reducer
 * @param  {Object} state
 * @param  {string} action
 * @return {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_OWN_PROFILE_REQUEST:
      newState = { ownUser: { loading: true } };
      return { ...state, ...newState };

    case FETCH_OWN_PROFILE_SUCCESS: {
      newState = { ownUser: { ...action.payload, loading: false } };
      const rolesMap = newState.ownUser.roles.reduce((acc, role) => ({ ...acc, [role]: true }), {});
      Object.entries(AdminRoles)
        .forEach(([key, value]) => newState.ownUser[`is${CaseHelper.fromUpperSnakeToPascalCase(key)}`] = !!rolesMap[value]);
      return { ...state, ...newState };
    }
    default:
      return state;
  }
};
