import {
  all, put, call, takeEvery, fork,
} from 'redux-saga/effects';
import RestManager from '@util/RestManager';
import { ENDPOINTS, BASE_MOB, BASE_RUBY } from '@constants/UHEEndpoints';
import { fetchError, showMessage, showWarning } from '@actions/Common';
import { GET_LANGUAGES, INVITE_INTERPRETER_REQUEST, VMR_REQUEST_INT } from '@constants/UHEActionTypes';
import {
  getLanguagesSuccess, getVmrSuccess, inviteInterpreterSuccess,
} from '@actions/uhe/InterpreterActions';
import { redirectToApsOptions } from '@actions/uhe/GuestActions';

/**
   * Sends GET Request to /api/invite/interpreter?customer=XYZ
   * @param {string} data customer
   * @returns {object} Data
   */
const languageRequest = async (data) => {
  const customerName = data.replace(/\s/g, '2%').replace('_', '2%').replace('/', '2%');
  const request = RestManager.request(`${ENDPOINTS.interpreter.languages}${customerName}`, 'GET', [], true, BASE_MOB);

  return request;
};
/**
   * languages Saga Worker
   * @param {object} data customer possible languages
   * @returns {void}
   */
function* fetchLanguages(data) {
  try {
    const response = yield call(languageRequest, data.payload);

    if (response) {
      yield put(getLanguagesSuccess(response));
    }
  } catch (error) {
    yield put(fetchError({ ...error, msg: 'noCustomer' }));
  }
}
/**
   * Sends GET Request to api/me/vmr
   * @returns {object} Data
   */
const vmrRequest = async () => {
  const request = RestManager.request(`${ENDPOINTS.interpreter.vmr}`, 'GET', [], true, BASE_MOB);

  return request;
};

/**
   * VMR Saga Worker
   * @param {object} payload VMR DATA
   * @returns {void}
   */
function* fetchVmr() {
  try {
    const response = yield call(vmrRequest);

    if (response) {
      yield put(getVmrSuccess({ ...response }));
    }
  } catch (error) {
    yield put(fetchError({ ...error, msg: 'noVmr' }));
  }
}

/**
 * Sends POST Request to api/v1/rooms/control
 * @param {object} body Body Data
 * @returns {object} Data
 */
const sendApsCommandRequest = async (body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.guest.aps}`, 'POST', body, [], true, true, BASE_RUBY);

  return request;
};

/**
   * Sends POST Request to api/invite/interpreter
   * @param {object} body Body Data
   * @returns {object} Data
   */
const inviteInterpreterRequest = async (body) => {
  const endpoint = body.customer ? ENDPOINTS.interpreter.invite : ENDPOINTS.guest.invite;
  const request = RestManager.requestWithoutQueryParams(`${endpoint}`, 'POST', body, [], true, true, BASE_MOB);

  return request;
};

/**
   * sendInviteInterpreter Saga Worker
   * @param {object} data Invite Body Data
   * @returns {void}
   */
function* sendInviteInterpreter(data) {
  const body = data.payload.inviteBody ? data.payload.inviteBody : data.payload.inviteBodyPexip;
  try {
    const response = yield call(inviteInterpreterRequest, body);
    const responseAps = yield call(sendApsCommandRequest, data.payload.apsBody);
    if (response) {
      yield put(inviteInterpreterSuccess({ ...response }));
      if (responseAps.busy_status === 'open' && responseAps.success === 'true') {
        yield put(showMessage('inviteSuccess'));
      } else if (responseAps.busy_status === 'busy' && responseAps.success === 'false') {
        yield put(showWarning('apsBusy'));
      }
    }
    if (response[0].status === 'ACCEPTED' && responseAps.busy_status === 'open') {
      yield put(redirectToApsOptions(true));
    } else {
      yield put(redirectToApsOptions(false));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
   * Sagas Watcher
   * @returns {void}
   */
export function* sagasWatcher() {
  yield takeEvery(VMR_REQUEST_INT, fetchVmr);
  yield takeEvery(GET_LANGUAGES, fetchLanguages);
  yield takeEvery(INVITE_INTERPRETER_REQUEST, sendInviteInterpreter);
}

/**
   * Performs Non-Blocking Call
   * @returns {void}
   */
export default function* rootSaga() {
  yield all([fork(sagasWatcher)]);
}
