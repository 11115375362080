import {
  all, call, fork, put, select, takeEvery,
} from 'redux-saga/effects';
import RestManager from '@util/RestManager';
import { ENDPOINTS, BASE_MOB, BASE_RUBY } from '@constants/UHEEndpoints';
import { fetchError, showMessage, showWarning } from '@actions/Common';
import {
  getVmrSuccess, redirectToApsOptions, inviteParticipantSuccess,
} from '@actions/uhe/GuestActions';
import { VMR_REQUEST, INVITE_PARTICIPANT_REQUEST } from '@constants/UHEActionTypes';
import { isInCall } from '../../selectors/uhe/GuestSelectors';

/**
 * Sends GET Request to api/me/vmr
 * @returns {object} Data
 */
const vmrRequest = async () => {
  const request = RestManager.request(`${ENDPOINTS.guest.vmr}`, 'GET', [], true, BASE_MOB);

  return request;
};

/**
 * Sends POST Request to api/invite/participant
 * @param {object} body Body Data
 * @returns {object} Data
 */
const inviteParticipantRequest = async (body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.guest.invite}`, 'POST', body, [], true, true, BASE_MOB);

  return request;
};

/**
 * Sends POST Request to api/v1/rooms/control
 * @param {object} body Body Data
 * @returns {object} Data
 */
export const sendApsCommandRequest = async (body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.guest.aps}`, 'POST', body, [], true, true, BASE_RUBY);

  return request;
};

/**
 * VMR Saga Worker
 * @param {object} payload VMR DATA
 * @returns {void}
 */
function* fetchVmr() {
  try {
    const response = yield call(vmrRequest);

    if (response) {
      yield put(getVmrSuccess({ ...response }));
    }
  } catch (error) {
    yield put(fetchError({ ...error, msg: 'noVmr', code: `${error.code}` }));
  }
}

/**
 * handleInviteParticipant Saga Worker
 * @param {object} reqPayload Request Payload
 * @param {object} responseAps Response Data
 * @returns {void}
 */
function* handleInviteParticipant(reqPayload, responseAps = {}) {
  if (responseAps.busy_status === 'busy') {
    reqPayload.inviteBody.vmr = responseAps.vmr_alias;
  }

  const response = yield call(inviteParticipantRequest, reqPayload.inviteBody);

  if (response) {
    if (responseAps.busy_status === 'open' && responseAps.success === 'true') {
      yield put(inviteParticipantSuccess());
      yield put(showMessage('inviteSuccess'));
    } else if (responseAps.busy_status === 'busy' && responseAps.success === 'false') {
      yield put(showWarning('apsBusy'));
    } else {
      yield put(showWarning('apsIsOffline'));
    }
  }

  if (response[0].status === 'ACCEPTED' && responseAps.busy_status === 'open') {
    yield put(redirectToApsOptions(true));
  } else {
    yield put(redirectToApsOptions(false));
  }
}

/**
 * sendInviteParticipant Saga Worker
 * @param {object} data Invite Body Data
 * @param {object} apsBody APS Body Data
 * @returns {void}
 */
function* sendInviteParticipant(data) {
  try {
    const reqPayload = { ...data.payload };
    const inCall = yield select(isInCall);

    if (inCall) {
      yield call(handleInviteParticipant, reqPayload, { success: 'true', busy_status: 'open' });
    } else if (!reqPayload.inviteBody) {
      yield call(sendApsCommandRequest, data.payload.apsBody);
    } else {
      const responseAps = yield call(sendApsCommandRequest, data.payload.apsBody);
      yield call(handleInviteParticipant, reqPayload, responseAps);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sagas Watcher
 * @returns {void}
 */
export function* sagasWatcher() {
  yield takeEvery(VMR_REQUEST, fetchVmr);
  yield takeEvery(INVITE_PARTICIPANT_REQUEST, sendInviteParticipant);
}

/**
 * Performs Non-Blocking Call
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(sagasWatcher)]);
}
