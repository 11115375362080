import { VMR_SUCCESS_INT, GET_LANGUAGES_SUCCESS } from '@constants/UHEActionTypes';

const INIT_STATE = {
  vmr: {},
  languages: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VMR_SUCCESS_INT: {
      return { ...state, vmr: { ...action.payload } };
    }
    case GET_LANGUAGES_SUCCESS: {
      return { ...state, languages: [...action.payload] };
    }

    default:
      return state;
  }
};
