import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ParticipantsItem from '@components/aps/participants/ParticipantsItem';

/**
 * ParticipantsItem Class Component
 * @returns {void}
 */
class ParticipantsList extends Component {
  /**
   * ParticipantsList Constructor
   * @param {object} props ParticipantsList Props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Renders ParticipantsList Component
   * @returns {JSX.Element} ParticipantsList Component
   */
  render() {
    const { participants } = this.props;
    const participantList = Object.values(participants)
      .map((participant) => <ParticipantsItem participant={participant} key={participant.uuid} />);

    return (
      <ul className="participants-list">
        {participantList}
      </ul>
    );
  }
}

ParticipantsList.propTypes = {
  participants: PropTypes.shape().isRequired,
};

export default ParticipantsList;
