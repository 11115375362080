import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IntlMessages from '@util/IntlMessages';
import ApsFooter from '@components/aps/UI/ApsFooter';
import { showWarning } from '@actions/Common';

import logo from '@root/assets/images/logo_caregility_lg.svg';

import { getProfile } from '@uhe_actions/ProfileActions';
import { endpointsRequest } from '@uhe_actions/EndpointsActions';
import { getAccessToken } from '@util/UheHelper';
import { ACCESS_TOKEN_KEY } from '@constants/UHESettings';

/**
 * Homepage Component
 */
class HomePage extends Component {
  /**
    * HomePage Constructor
    * @param {object} props HomePage Props
    */
  constructor(props) {
    super(props);
    const { location } = this.props;

    this.state = {
      lastName: '',
      roomNumber: '',
    };

    this.qParams = new URLSearchParams(location.search);
    this.accessToken = this.qParams.get('token');
    this.apsId = this.qParams.get('aps_id');
    this.availableFunctions = this.qParams.get('available_functions');
    this.currentState = this.qParams.get('state');
    this.lastVmr = this.qParams.get('vmr');
  }

  /**
   * Handles Subscriptions and Profile Data Fetching
   * @returns {void}
   */
  componentDidMount() {
    const { endpointsRequestAction, history: { push } } = this.props;
    if (this.lastVmr) {
      sessionStorage.setItem('vmr', this.lastVmr);
    }
    if (this.availableFunctions) {
      sessionStorage.setItem('available_functions', this.availableFunctions);
    }
    if (this.accessToken && this.apsId) {
      sessionStorage.setItem(ACCESS_TOKEN_KEY, this.accessToken);
      sessionStorage.setItem('aps_id', this.apsId);
    }

    if (getAccessToken()) {
      endpointsRequestAction(this.apsId);
    }

    switch (this.currentState) {
      case 'entertainment':
        push('/entertainment');
        break;
      case 'clinician_call':
        push('/aps-options');
        break;
      default:
        push('/');
    }
  }

  /**
   * Handles Form submittion and set Session Storage
   * @param {object} event object
   * @returns {void}
   */
  submitHandler = (event) => {
    event.preventDefault();

    const { history, showWarning, profile } = this.props;
    const { customer } = profile;
    const { lastName, roomNumber } = this.state;
    const token = sessionStorage.getItem('accessToken');
    const aps_id = sessionStorage.getItem('aps_id');
    if (customer) {
      sessionStorage.setItem('customer', customer);
    }

    if (token === null || aps_id === null) {
      showWarning('missingUrlCredentials');
      return;
    }

    if (lastName !== '' && roomNumber !== '') {
      sessionStorage.setItem('lastName', lastName);
      sessionStorage.setItem('roomNumber', roomNumber);

      history.replace('/main');
    }
  }

  /**
   * Handles onChange event for changing lastName
   * @param {object} event object
   * @returns {void}
   */
  changeLastNameHandler = (event) => {
    this.setState({ lastName: event.target.value.trim() });
  }

  /**
   *  Handles onChange event for changing room number
   * @param {object} event object
   * @returns {void}
   */
  changeRoomNumberHandler = (event) => {
    this.setState({ roomNumber: event.target.value.trim() });
  }

  /**
  * Renders HomePage Component
  * @returns {JSX.Element} HomePage Component
  */
  render() {
    const { roomNumber, lastName } = this.state;
    const { intl } = this.props;

    return (
      <div className="aps-layout">
        <div className="homepage">
          <div className="homepage_head">
            <h3>
              <IntlMessages id="common.welcome" />
            </h3>
            <img src={logo} alt={intl.formatMessage({ id: 'common.caregilityLogo' })} />
            <p><IntlMessages id="homePage.homepageIntro" /></p>
          </div>
          <div className="homepage_body">
            <form onSubmit={this.submitHandler} className="form">
              <input
                type="text"
                placeholder={intl.formatMessage({ id: 'homePage.lastName' })}
                value={lastName}
                onChange={this.changeLastNameHandler}
                className="form-element"
              />
              <input
                type="text"
                placeholder={intl.formatMessage({ id: 'homePage.roomNumber' })}
                value={roomNumber}
                onChange={this.changeRoomNumberHandler}
                className="form-element"
              />
              <button type="submit" className="form-element form-button">
                <IntlMessages id="homePage.enter" />
              </button>
            </form>
          </div>
        </div>
        <ApsFooter />
      </div>
    );
  }
}

/**
 * Maps State to Props
 * @param {string} endpoints destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ endpoints }) => ({
  profile: endpoints.endpoint,
});
/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  endpointsRequestAction: (payload) => dispatch(endpointsRequest(payload)),
  showWarning: (message) => dispatch(showWarning(message)),
});

HomePage.propTypes = {
  location: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  showWarning: PropTypes.func.isRequired,
  endpointsRequestAction: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage)));
