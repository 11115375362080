import {
  VMR_REQUEST, VMR_SUCCESS, REDIRECT_TO_APS_OPTIONS,
  INVITE_PARTICIPANT_SUCCESS, IN_CALL, REDIRECT_TO_MAIN_PAGE,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  vmr: {},
  inCall: false,
  redirectToApsOptionsPage: false,
  redirectToMainPage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VMR_REQUEST:
      return { ...state };

    case VMR_SUCCESS: {
      return { ...state, vmr: { ...action.payload } };
    }

    case INVITE_PARTICIPANT_SUCCESS: {
      return { ...state, inCall: true };
    }

    case REDIRECT_TO_APS_OPTIONS: {
      return { ...state, redirectToApsOptionsPage: action.payload };
    }

    case IN_CALL: {
      return { ...state, inCall: false };
    }

    case REDIRECT_TO_MAIN_PAGE: {
      return { ...state, redirectToMainPage: action.payload };
    }

    default:
      return state;
  }
};
