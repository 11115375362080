import React, { Component } from 'react';
import {
  Form, Input, Button, Divider,
} from 'antd';
import PexRTC from '@util/PexRTC';
import { connect } from 'react-redux';
import { chatMessage } from '@uhe_actions/PexipActions';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import IntlMessages from '@util/IntlMessages';
import closeIcon from '@root/assets/images/invite_details_arrow_closed_over.svg';
import PropTypes from 'prop-types';
import Conversation from '@components/aps/chat/Conversation/index';
import sentIcon from '@root/assets/images/chat-page/sent.svg';

/**
 * Chat Page Component
 */
export class ChatPage extends Component {
  formRef = React.createRef();

  inputRef = React.createRef();

  bottomRef = React.createRef();

  /**
   * ChatPage Constructor
   * @param {object} props ChatPage Props
   */
  constructor(props) {
    super(props);
    this.state = {
      msgFlag: true,
    };
  }

  /**
   * componentDidMount
   * @returns {function} function for scrolling
   */
  componentDidMount() {
    PexRTC.onChatMessage = this.getMessage;
    this.inputRef.current.focus();
    this.scrollToBottom();
  }

  /**
   * componentDidUpdate
   * @returns {function} function for scrolling
   */
  componentDidUpdate() {
    this.scrollToBottom();
  }

  /**
   * scroll chat always to the newest message
   * @returns {void}
   */
  scrollToBottom = () => {
    this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * getMessage
   * @param {string} message message body
   * @returns {object} chatMessageAction
   */
     getMessage = (message) => {
       const { chatMessageAction } = this.props;
       const { msgFlag } = this.state;
       const { origin, payload } = message;
       const lastAt = moment().format('LTS');
       const msgBody = {
         display_name: origin, message: payload, type: 'receive', lastAt,
       };
       chatMessageAction(msgBody);
       this.setState({ msgFlag: !msgFlag });
     }

  /**
   * chatMessage
   * @param {string} message message body
   * @returns {object} pexip method
   */
  chatMessage = (message) => {
    PexRTC.sendChatMessage(message);
  }

    /**
     * handle form subscription
     * @param {string} values form values
     * @returns {object} form value
     */
    onFinish = ({ message }) => {
      this.chatMessage(message);
      const { msgFlag } = this.state;
      const { participants, chatMessageAction } = this.props;
      const participantList = Object.values(participants);
      const me = participantList.filter((participant) => participant.protocol === 'api');
      const { display_name } = me[0];
      const lastAt = moment().format('LTS');
      const msgObj = {
        display_name, message, type: 'sent', lastAt,
      };
      chatMessageAction(msgObj);
      this.formRef.current.resetFields();
      this.inputRef.current.focus();
      this.setState({ msgFlag: !msgFlag });
    }

    /**
   * Handles close button
   * @returns {void}
   */
  redirectOnClose = () => {
    const { history } = this.props;
    history.push('/aps-options');
  }

  /**
  * Renders the component
  * @returns {JSX} renders the component
  */
  render() {
    const { messages, intl } = this.props;

    return (
      <>
        <div className="headline-flex chat-header">
          <Button
            className="chat-back-btn"
            size="small"
            type="text"
            icon={<img src={closeIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-back-img" />}
            onClick={this.redirectOnClose}
          />
          <h1 className="headline">
            <IntlMessages id="chat.roomName" />
          </h1>
          <div />
        </div>
        <Divider className="chat-divider" />
        <div className="chat-wrapper">
          {messages.length > 0 && <p className="chat-start-time">{messages[0].lastAt}</p>}
          <Conversation conversationData={messages} />
          <div className="empty-div" ref={this.bottomRef} />
        </div>
        <Divider className="divider" />
        <Form className="gx-chat-main-footer chat-form" onFinish={this.onFinish} ref={this.formRef}>
          <div className="gx-flex-row gx-align-items-center chat-input-wrapper">
            <div className="gx-col chat-input">
              <div className="gx-form-group">
                <Form.Item name="message" className="chat-form-item">
                  <Input
                    className="gx-border-0 ant-input gx-chat-textarea chat-input-field"
                    placeholder={intl.formatMessage({ id: 'chat.message' })}
                    ref={this.inputRef}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="chat-form-item">
              <Button
                htmlType="submit"
                className="chat-send-btn"
                type="primary"
                shape="circle"
                icon={<img src={sentIcon} alt={intl.formatMessage({ id: 'guest.close' })} className="chat-sent-icon" />}
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

ChatPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  participants: PropTypes.shape().isRequired,
  chatMessageAction: PropTypes.func.isRequired,
  messages: PropTypes.shape().isRequired,
};
/**
 * Maps State to Props
 * @returns {object} Object with States
 */
const mapStateToProps = ({ pexip }) => ({
  messages: pexip.messages,
  participants: pexip.participants,
});

/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  chatMessageAction: (payload) => dispatch(chatMessage(payload)),
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatPage)));
