import {
  VMR_REQUEST,
  VMR_SUCCESS,
  INVITE_PARTICIPANT_REQUEST,
  REDIRECT_TO_APS_OPTIONS,
  INVITE_PARTICIPANT_SUCCESS,
  IN_CALL,
  REDIRECT_TO_MAIN_PAGE,
} from '@constants/UHEActionTypes';

/**
 * getVmr Action
 * @returns {object} VMR Data
 */
export const getVmr = () => ({
  type: VMR_REQUEST,
});

/**
 * getVmrSuccess Action
 * @param {object} payload Data
 * @returns {object} VMR Data
 */
export const getVmrSuccess = (payload) => ({
  type: VMR_SUCCESS,
  payload,
});

/**
 * inviteParticipant Action
 * @param {object} payload Body
 * @returns {object} Data
 */
export const inviteParticipant = (payload) => ({
  type: INVITE_PARTICIPANT_REQUEST,
  payload,
});

/**
 * inviteParticipant Action
 * @param {object} payload Body
 * @returns {object} Data
 */
export const inviteParticipantSuccess = () => ({
  type: INVITE_PARTICIPANT_SUCCESS,
});

/**
 * Redirect Action
 * @param {object} payload Body
 * @returns {object} Data
 */
export const redirectToApsOptions = (payload) => ({
  type: REDIRECT_TO_APS_OPTIONS,
  payload,
});

/**
 * Redirect Action
 * @param {object} payload Body
 * @returns {object} Data
 */
export const redirectToMainPage = (payload) => ({
  type: REDIRECT_TO_MAIN_PAGE,
  payload,
});

/**
 * setInCall Action Creator
 * @returns {object} Action Type
 */
export const setInCall = () => ({
  type: IN_CALL,
});
