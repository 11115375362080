import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Homepage from '@components/aps/HomePage';
import Mainpage from '@components/aps/MainPage';
import Guest from '@components/aps/GuestPage';
import Interpreter from '@components/aps/InterpreterPage';
import Entertainment from '@components/aps/EntertainmentPage';
import DeviceControlPage from '@components/aps/DeviceControlPage';
import ApsOptions from '@components/aps/ApsOptionsPage';
import Participants from '@components/aps/ParticipantsPage';
import ChatPage from '@components/aps/ChatPage';
import ExpiredSessionPage from '@components/aps/ExpiredSessionPage';

/**
 * App Component
 * @returns {void}
 */
const App = ({ match, location: { pathname } }) => (
  <div
    className="gx-main-content-wrapper "
  >
    <div className={`container -${pathname.toUpperCase().replaceAll('/', 's')}`}>
      <Switch>
        <Route exact path="/" component={Mainpage} />
        <Route path={`${match.url}main`} component={Mainpage} />
        <Route path={`${match.url}guest`} component={Guest} />
        <Route path={`${match.url}interpreter`} component={Interpreter} />
        <Route path={`${match.url}entertainment`} component={Entertainment} />
        <Route path={`${match.url}device-control`} component={DeviceControlPage} />
        <Route path={`${match.url}aps-options`} component={ApsOptions} />
        <Route path={`${match.url}participants`} component={Participants} />
        <Route path={`${match.url}chat`} component={ChatPage} />
        <Route path={`${match.url}session-expired`} component={ExpiredSessionPage} />
      </Switch>
    </div>
  </div>
);

App.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,

};

export default App;
