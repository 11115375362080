import {
  FETCH_OWN_PROFILE_REQUEST,
  FETCH_OWN_PROFILE_SUCCESS,
} from '@constants/UHEActionTypes';

/**
 * Fetch own user profile
 * @return {object} User's Data
 */
export const onFetchOwnPofile = () => ({
  type: FETCH_OWN_PROFILE_REQUEST,
});

/**
 * Fetch own user profile
 * @param {object} data Data
 * @return {object} User's Data
 */
export const onFetchOwnPofileSuccess = (data) => ({
  type: FETCH_OWN_PROFILE_SUCCESS,
  payload: data,
});
