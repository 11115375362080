import {
  ENDPOINTS_REQUEST, ENDPOINTS_SUCCESS, END_CALL_REQUEST,
} from '@constants/UHEActionTypes';

/**
 * endpointsRequest Action
 * @param {object} payload aps_id
 * @returns {void}
 */
export const endpointsRequest = (payload) => ({
  type: ENDPOINTS_REQUEST,
  payload,
});

/**
 * endpointsSuccess Action
 * @param {object} payload Data
 * @returns {void}
 */
export const endpointsSuccess = (payload) => ({
  type: ENDPOINTS_SUCCESS,
  payload,
});

/**
 * endCall Action Creator
 * @param {object} payload Body Data
 * @returns {object} Type, Body
 */
export const endCall = (payload) => ({
  type: END_CALL_REQUEST,
  payload,
});
