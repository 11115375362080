import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover, Button } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from 'react-intl';
import UheHelper from 'util/UheHelper';
import { onFetchOwnPofile } from '@uhe_actions/configuration/users/UsersActions';

class UserProfile extends React.Component {
  static userMenuOptions() {
    return (
      <ul className="gx-user-popover">
        <li>
          <a rel="noopener noreferrer" target="_blank" href={window.env_overrides.REACT_APP_CLINICIAN_URL || process.env.REACT_APP_CLINICIAN_URL}>
            <IntlMessages id="profile.iConsult" />
          </a>
        </li>
        <li>
          <a rel="noopener noreferrer" target="_blank" href={window.env_overrides.REACT_APP_IOBSERVER_URL || process.env.REACT_APP_IOBSERVER_URL}>
            <IntlMessages id="profile.iObserver" />
          </a>
        </li>
        <li>
          <a href={window.env_overrides.REACT_APP_RESET_PASSWORD || process.env.REACT_APP_RESET_PASSWORD}>
            <IntlMessages id="profile.resetPassword" />
          </a>
        </li>
        <li>
          <Button
            onClick={() => {
              UheHelper.clearAuthTokens();
              UheHelper.removeOwnUserProfile();
              window.location.href = (window.env_overrides.REACT_APP_SSO_REDIRECT_URL || process.env.REACT_APP_SSO_REDIRECT_URL).replace('?', 'logout/?');
            }}
            className="logout-button"
          >
            <IntlMessages id="profile.logout" />
          </Button>
        </li>
        {UheHelper.getImpersonateAuthToken() && (
          <li>
            <Button onClick={() => {
              UheHelper.clearImpersonateAuthTokens();
              UheHelper.removeOwnUserProfile();
              window.location.reload();
            }}
            >
              <IntlMessages id="profile.endImpersonate" />
            </Button>
          </li>
        )}
      </ul>
    );
  }

  constructor(props) {
    super(props);

    this.props.onFetchOwnPofile();
  }

  render() {
    const { intl, profile } = this.props;
    let displayName = '';
    if (profile) {
      displayName = profile.username;

      if (profile.firstName || profile.lastName) {
        displayName = `${profile.firstName} ${profile.lastName}`;
      }
    }

    return (
      <div className="gx-d-flex gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={UserProfile.userMenuOptions()}
          trigger="click"
        >
          {UheHelper.getImpersonateAuthToken() && (
            <i
              className="icon icon-user-o gx-fs-xl gx-mr-1"
              title={intl.formatMessage({ id: "profile.impersonating" })}
            />
          )}
          <div className="gx-avatar-name gx-mt-4">
            {displayName}
            <i className="icon icon-dropdown gx-fs-xxs gx-ml-2" />
          </div>
        </Popover>
      </div>
    );
  }
}

UserProfile.propTypes = {
  intl: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = ({ ConfigurationUsers }) => {
  UheHelper.setOwnUserProfile(ConfigurationUsers.ownUser);

  return { profile: ConfigurationUsers.ownUser };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchOwnPofile: () => dispatch(onFetchOwnPofile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserProfile));
