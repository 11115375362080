import enLang from "./entries/en-US";
import arLang from "./entries/ar_SA";
import jaLang from "./entries/ja_JP";

const AppLocale = {
  en: enLang,
  ar: arLang,
  ja: jaLang,
};

export default AppLocale;
