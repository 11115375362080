import { PROFILE_REQUEST, PROFILE_SUCCESS } from '@constants/UHEActionTypes';

/**
 * getProfile Action
 * @returns {object} Profile Data
 */
export const getProfile = () => ({
  type: PROFILE_REQUEST,
});

/**
 * getProfileSuccess Action
 * @param {object} payload Data
 * @returns {object} Profile Data
 */
export const getProfileSuccess = (payload) => ({
  type: PROFILE_SUCCESS,
  payload,
});
