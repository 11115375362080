export const ACCESS_TOKEN_PARAM = 'access_token';
export const REFRESH_TOKEN_PARAM = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const CURRENT_USER_KEY = 'ownUserProfile';
export const IMPERSONATE_TOKEN = 'impersonate_token';
export const INVITE_TOKEN = 'invite_token';
export const CALL_ROOM_TOKEN = 'call_token';

export const HTTP_ERROR_MESSAGES = {
  400: 'bad_request',
  403: 'forbidden',
  404: 'not_found',
  409: 'already_exists',
  500: 'server_error',
  502: 'bad_gateway',
  503: 'service_not_available',
  504: 'gateway_timeout',
};

// Guest Page
export const SMS_VALUE = 'SMS';
export const EMAIL_VALUE = 'Email';
export const PHONE_VALUE = 'Phone';

// Device Control Page
export const CONTROL_VALUE = 'Control';
export const PINS_VALUE = 'Pins';
export const SETTINGS_VALUE = 'Settings';
export const SPEAKER_VALUE = 'speakerValue';
export const MIC_VALUE = 'micValue';
export const BRIGHTNESS_VALUE = 'brightnessValue';
export const SPEED_VALUE = 'speedValue';
export const FOCUS_VALUE = 'focusValue';
export const DOORBELL_VALUE = 'doorbellValue';

// Interpreter Page
export const ANY = 'ANY';
export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const REQUEST_TYPE = 'REQUEST';

// PEXIP
export const PEXIP_URL = 'proxy.caregility.net';
