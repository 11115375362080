import {
  FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, SHOW_WARNING,
} from 'constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  message: '',
  warning_msg: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state, error: '', message: '', loading: true, warning_msg: '',
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state, error: '', message: '', loading: false, warning_msg: '',
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state, error: '', message: action.payload, loading: false, warning_msg: '',
      };
    }
    case FETCH_ERROR: {
      return {
        ...state, loading: false, error: '', message: '', warning_msg: action.payload.code || action.payload.msg,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state, loading: false, error: '', message: '', warning_msg: '',
      };
    }
    case SHOW_WARNING: {
      return {
        ...state, loading: false, error: '', message: '', warning_msg: action.payload,
      };
    }
    default:
      return state;
  }
};
