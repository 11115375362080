import React from 'react';
import { Typography } from 'antd';

import IntlMessages from '@util/IntlMessages';
import warningIcon from '@root/assets/images/warning.svg';

const { Text } = Typography;

/**
 * Renders ExpiredSessionPage Component
 * @returns {JSX.Element} ExpiredSessionPage Component
 */
const ExpiredSessionPage = () => (
  <div className="session-expirerd-wrapper">
    <div className="entertainment-cast-icon">
      <img src={warningIcon} alt="Warning Icon" />
    </div>
    <h2 className="gx-m-4 session-expired-title">
      <IntlMessages id="sessionExpired.title" />
    </h2>
    <div className="gx-d-flex gx-justify-content-center">
      <Text className="session-expired-txt">
        <IntlMessages id="sessionExpired.firstParagraph" />
      </Text>
    </div>
  </div>
);

export default ExpiredSessionPage;
