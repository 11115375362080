export const CAREGILITY_SYSTEM_ADMIN = 'SUPER_ADMIN';
export const USER_ADMIN = 'USER_ADMIN';
export const CAREGILITY_ADMIN = 'CAREGILITY_ADMIN';
export const CUSTOMER_ADMIN = 'CUSTOMER_ADMIN';
export const ORGANIZATION_ADMIN = 'CHANNEL_ADMIN';
export const FACILITY_ADMIN = 'FACILITY_ADMIN';
export const UNIT_ADMIN = 'UNIT_ADMIN';
export const TECHNICIAN_ADMIN = 'TECH_ADMIN';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const CHANNEL_ADMIN = 'CHANNEL_ADMIN';
export const TECHNICIAN = 'TECH';
