import {
  all, takeEvery, fork, call, put,
} from 'redux-saga/effects';
import {
  ZOOM_REQUEST, STOP_REQUEST, PAN_REQUEST, SPEAKER_REQUEST, MIC_REQUEST, BRIGHTNESS_REQUEST,
  SPEED_REQUEST, FOCUS_REQUEST, DOORBELL_REQUEST, GET_PINS, DELETE_PIN_REQUEST, ADD_PIN_REQUEST,
  GO_TO_PIN_REQUEST, ENTERTAINMENT_REQUEST, SELFVIEW_REQUEST, SELF_MUTE_AUDIO, SELF_MUTE_VIDEO,
} from '@constants/UHEActionTypes';
import {
  getZoomSuccess, getStopSuccess, getPanSuccess, getSpeakerSuccess, getMicSuccess, getBrightSuccess,
  getSpeedSuccess, getFocusSuccess, getDoorbellSuccess, getPinsSuccess,
} from '@actions/uhe/DeviceControlActions';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';
import { ENDPOINTS, BASE_RUBY } from '@constants/UHEEndpoints';

/**
 * Sends GET Request to /api/v1/cameras/:customer/:location/:room
 * @param {object} data customer data
 * @returns {object} Data
 */
const pinsRequest = async (data) => {
  const { customer, facility, unit } = data;
  const customerData = customer.replace('/', '-');
  const facilityData = facility.replace('/', '-');
  const unitData = unit.replace('/', '-');
  const request = RestManager.request(`${ENDPOINTS.pins}/${customerData}/${facilityData}/${unitData}`, 'GET', [], true, BASE_RUBY);
  return request;
};
/**
 * fetchPins Saga Worker
 * @param {object} data customer data
 * @returns {void}
 */
function* fetchPins(data) {
  try {
    const response = yield call(pinsRequest, data.payload);
    if (response) {
      yield put(getPinsSuccess(response.units));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Send POST request to aps/api/v1/rooms/control
 * @param {object} body body for the post request
 * @returns {object} data
 */
const commandRequest = (body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.deviceControl}`, 'POST', body, [], true, true, BASE_RUBY);
  return request;
};

/**
 * fetchSelfAudio Saga Worker
 * @param {object} data setFocus feature data
 * @return {void}
 */
function* fetchSelfAudio(data) {
  try {
    yield call(commandRequest, data.payload.selfAudioCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchSelfVideo Saga Worker
 * @param {object} data setFocus feature data
 * @return {void}
 */
function* fetchSelfVideo(data) {
  try {
    yield call(commandRequest, data.payload.selfVideoCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchDoorbell Saga Worker
 * @param {object} data setDoorbell feature data
 * @return {void}
 */
function* fetchDoorbell(data) {
  try {
    const response = yield call(commandRequest, data.payload.doorbellCommand);
    if (response) {
      yield put(getDoorbellSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchFocus Saga Worker
 * @param {object} data setFocus feature data
 * @return {void}
 */
function* fetchFocus(data) {
  try {
    const response = yield call(commandRequest, data.payload.focusCommand);
    if (response) {
      yield put(getFocusSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchSpeed Saga Worker
 * @param {object} data setSpeed feature data
 * @return {void}
 */
function* fetchSpeed(data) {
  try {
    const response = yield call(commandRequest, data.payload.speedCommand);
    if (response) {
      yield put(getSpeedSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchBrightness Saga Worker
 * @param {object} data setBrightnes feature data
 * @return {void}
 */
function* fetchBrightness(data) {
  try {
    const response = yield call(commandRequest, data.payload.brightnessCommand);
    if (response) {
      yield put(getBrightSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchMic Saga Worker
 * @param {object} data setMic feature data
 * @return {void}
 */
function* fetchMic(data) {
  try {
    const response = yield call(commandRequest, data.payload.micCommand);
    if (response) {
      yield put(getMicSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchSpeaker Saga Worker
 * @param {object} data setSpeaker feature data
 * @return {void}
 */
function* fetchSpeaker(data) {
  try {
    const response = yield call(commandRequest, data.payload.speakerCommand);
    if (response) {
      yield put(getSpeakerSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * fetchZoom Saga Worker
 * @param {object} data zoom feature data
 * @return {void}
 */
function* fetchZoom(data) {
  try {
    const response = yield call(commandRequest, data.payload.zoomCommand);
    if (response) {
      yield put(getZoomSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchStop Saga Worker
 * @param {object} data stop feature data
 * @return {void}
 */
function* fetchStop(data) {
  try {
    const response = yield call(commandRequest, data.payload.stopCommand);
    if (response) {
      yield put(getStopSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * fetchStop Saga Worker
 * @param {object} data stop feature data
 * @return {void}
 */
function* fetchPan(data) {
  try {
    const response = yield call(commandRequest, data.payload.panCommand);
    if (response) {
      yield put(getPanSuccess());
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * delPin Saga Worker
 * @param {object} data delPin feature data
 * @return {void}
 */
function* delPin(data) {
  try {
    yield call(commandRequest, data.payload.delPinCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * addPin Saga Worker
 * @param {object} data addPin feature data
 * @return {void}
 */
function* addPin(data) {
  try {
    yield call(commandRequest, data.payload.addPinCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * goToPinFetch Saga Worker
 * @param {object} data goToPin feature data
 * @return {void}
 */
function* goToPinFetch(data) {
  try {
    yield call(commandRequest, data.payload.goToPinCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * fetchSelfView Saga Worker
 * @param {object} data self-view feature data
 * @return {void}
 */
function* fetchSelfView(data) {
  try {
    yield call(commandRequest, data.payload.selfViewCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * fetchEntertainment Saga Worker
 * @param {object} data entertainment feature data
 * @return {void}
 */
function* fetchEntertainment(data) {
  try {
    yield call(commandRequest, data.payload.entertainmentCommand);
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
* Sagas Watcher
* @returns {void}
*/
export function* sagasWatcher() {
  yield takeEvery(ZOOM_REQUEST, fetchZoom);
  yield takeEvery(STOP_REQUEST, fetchStop);
  yield takeEvery(PAN_REQUEST, fetchPan);
  yield takeEvery(SPEAKER_REQUEST, fetchSpeaker);
  yield takeEvery(MIC_REQUEST, fetchMic);
  yield takeEvery(BRIGHTNESS_REQUEST, fetchBrightness);
  yield takeEvery(SPEED_REQUEST, fetchSpeed);
  yield takeEvery(FOCUS_REQUEST, fetchFocus);
  yield takeEvery(DOORBELL_REQUEST, fetchDoorbell);
  yield takeEvery(GET_PINS, fetchPins);
  yield takeEvery(DELETE_PIN_REQUEST, delPin);
  yield takeEvery(ADD_PIN_REQUEST, addPin);
  yield takeEvery(GO_TO_PIN_REQUEST, goToPinFetch);
  yield takeEvery(SELFVIEW_REQUEST, fetchSelfView);
  yield takeEvery(ENTERTAINMENT_REQUEST, fetchEntertainment);
  yield takeEvery(SELF_MUTE_VIDEO, fetchSelfVideo);
  yield takeEvery(SELF_MUTE_AUDIO, fetchSelfAudio);
}
/**
* Performs Non-Blocking Call
* @returns {void}
*/
export default function* rootSaga() {
  yield all([fork(sagasWatcher)]);
}
