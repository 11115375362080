import cloneDeep from 'lodash/cloneDeep';

import {
  PEXIP_UPDATE_PARTICIPANT,
  PEXIP_REMOVE_PARTICIPANT,
  PEXIP_CHAT_MESSAGE,
} from '@constants/UHEActionTypes';

const INIT_STATE = {
  participants: {},
  messages: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PEXIP_UPDATE_PARTICIPANT: {
      return {
        ...state,
        participants: {
          ...state.participants,
          ...action.payload,
        },
      };
    }

    case PEXIP_REMOVE_PARTICIPANT: {
      const newState = cloneDeep(state);
      delete newState.participants[action.payload.uuid];

      return newState;
    }
    case PEXIP_CHAT_MESSAGE: {
      return { ...state, messages: state.messages.concat(action.payload) };
    }

    default:
      return state;
  }
};
