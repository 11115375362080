import React, { useEffect } from 'react';
import { Layout, Popover, Radio } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import languageData from './languageData';
import { switchLanguage, toggleCollapsedSideNav } from '@actions/Setting';
import UserInfo from 'components/UserInfo';
import AppNotification from 'components/AppNotification';
import MailNotification from 'components/MailNotification';
import Auxiliary from 'util/Auxiliary';
import logo from '@root/assets/images/c-logo.png';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from '@constants/ThemeSetting';
import UserProfile from '../Sidebar/UserProfile';
const { Header } = Layout;
const LANG_SELECTOR_ENABLED = true;

const Topbar = () => {
  const { locale, width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const { langId } = useSelector(
    ({ subtitle }) => subtitle
  );
  const dispatch = useDispatch();
  const saveLang = (lang) => {
    localStorage.setItem('appLang', JSON.stringify(lang));
    dispatch(switchLanguage(lang));
  };

  useEffect(() => {
    const langFromSettings = localStorage.getItem('appLang');
    if (langFromSettings) {
      dispatch(switchLanguage(JSON.parse(langFromSettings)));
      return;
    }
    const browserLangs = navigator.languages;
    if (browserLangs && Array.isArray(browserLangs)) {
      const browserLang = browserLangs[0].split('-')[0];
      const lang = languageData.find(l => l.locale === browserLang);
      if (lang) {
        dispatch(switchLanguage(lang));
      }
    }
  }, []);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => saveLang(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  return (
    <Header>
      <Link
        to="/"
        className="logo-sm gx-d-block gx-d-lg-none gx-pointer gx-d-flex gx-justify-content-start"
      >
        <img
          alt=""
          className="logo-sm"
          src={require('assets/images/c-logo.png')}
        />
      </Link>

      <h1 className="gx-m-auto gx-d-none gx-d-lg-block topbar-title">
        <img src={logo} alt="" />

        {langId && <IntlMessages id={langId} />}
      </h1>
    </Header>
  );
};

export default Topbar;
