import enMessages from "../locales/en_US.json";
import jaMessages from "../locales/ja_JP.json";
import lodash from 'lodash';

const jpMerge = lodash.merge(lodash.cloneDeep(enMessages), lodash.cloneDeep(jaMessages))

const JpLang = {
  messages: {
    ...jpMerge,
  },
  locale: 'ja',
};
export default JpLang;