import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

/**
 * Renders Sent Chat Message
 * @param {object} conversation Message Data
 * @returns {JSX.Element} Sent Chat Message
 */
const SentMessageCell = ({ conversation }) => (
  <div className="gx-chat-item gx-flex-row-reverse">
    <Avatar
      className="gx-size-40 gx-align-self-end"
      alt={conversation.display_name}
    >
      {conversation.display_name.split(': ').pop().substring(0, 3).toUpperCase()}
    </Avatar>
    <div className="gx-bubble-block chat-bubble-block">
      <div className="chat-name-block sent-msg">
        <span>
          {conversation.display_name || 'Me'}
        </span>
      </div>
      <div
        className="gx-bubble chat-message-sent-block"
      >
        <div className="gx-message chat-message">{conversation.message}</div>
        <div className="gx-time gx-text-right gx-mt-2">{conversation.lastAt}</div>
      </div>
    </div>

  </div>
);

SentMessageCell.propTypes = {
  conversation: PropTypes.shape().isRequired,
};

export default SentMessageCell;
