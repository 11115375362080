// Users
export const FETCH_OWN_PROFILE_REQUEST = 'FETCH_OWN_PROFILE_REQUEST';
export const FETCH_OWN_PROFILE_SUCCESS = 'FETCH_OWN_PROFILE_SUCCESS';

// Domains
export const DOMAIN_FETCH_DATA = 'DOMAIN_FETCH_DATA';
export const DOMAIN_FETCH_DATA_SUCCESS = 'DOMAIN_FETCH_DATA_SUCCESS';
export const SAVE_DOMAIN_DATA = 'SAVE_DOMAIN_DATA';
export const SAVE_DOMAIN_DATA_SUCCESS = 'SAVE_DOMAIN_DATA_SUCCESS';
export const SAVE_DOMAIN_ID = 'SAVE_DOMAIN_ID';
export const SAVE_DOMAIN_ID_SUCCESS = 'SAVE_DOMAIN_ID_SUCCESS';
export const DELETE_DOMAIN_REQUEST = 'DELETE_DOMAIN_REQUEST';
export const DELETE_DOMAIN_REQUEST_SUCCESS = 'DELETE_DOMAIN_REQUEST_SUCCESS';
export const CLEAR_DOMAIN_STATE = 'CLEAR_DOMAIN_STATE';

// Guest
export const VMR_REQUEST = 'VMR_REQUEST';
export const VMR_SUCCESS = 'VMR_SUCCESS';
export const INVITE_PARTICIPANT_REQUEST = 'INVITE_PARTICIPANT_REQUEST';
export const INVITE_PARTICIPANT_SUCCESS = 'INVITE_PARTICIPANT_SUCCESS';
export const REDIRECT_TO_APS_OPTIONS = 'REDIRECT_TO_APS_OPTIONS';
export const IN_CALL = 'IN_CALL';

// Interpreter
export const VMR_REQUEST_INT = 'VMR_REQUEST_INT';
export const VMR_SUCCESS_INT = 'VMR_SUCCESS_INT';
export const INVITE_INTERPRETER_REQUEST = 'INVITE_INTERPRETER_REQUEST';
export const INVITE_INTERPRETER_SUCCESS = 'INVITE_INTERPRETER_SUCCESS';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
// Profile
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';

// Clinician
export const PATIENT_CALL_REQUEST = 'PATIENT_CALL_REQUEST';
export const PATIENT_CALL_SUCCESS = 'PATIENT_CALL_SUCCESS';
export const REDIRECT_TO_MAIN_PAGE = 'REDIRECT_TO_MAIN_PAGE';
export const CLINICAL_NOTIFICATION_REQUEST = 'CLINICAL_NOTIFICATION_REQUEST';

// Endpoints
export const ENDPOINTS_REQUEST = 'ENDPOINTS_REQUEST';
export const ENDPOINTS_SUCCESS = 'ENDPOINTS_SUCCESS';

// Device Control
export const ZOOM_REQUEST = 'ZOOM_REQUEST';
export const ZOOM_SUCCESS = 'ZOOM_SUCCESS';
export const ZOOM_IN = 'tele';
export const ZOOM_OUT = 'wide';
export const STOP_REQUEST = 'STOP_REQUEST';
export const STOP_SUCCESS = 'STOP_SUCCESS';
export const PAN_REQUEST = 'PAN_REQUEST';
export const PAN_SUCCESS = 'PAN_SUCCESS';
export const END_CALL_REQUEST = 'END_CALL_REQUEST';
export const TAB_CONTROL = 'CONTROL';
export const TAB_SETTINGS = 'SETTINGS';
export const CONTROL_UP = 'up';
export const CONTROL_DOWN = 'down';
export const CONTROL_RIGHT = 'right';
export const CONTROL_LEFT = 'left';
export const CONTROL_UPRIGHT = 'upright';
export const CONTROL_DOWNRIGHT = 'downright';
export const CONTROL_UPLEFT = 'upleft';
export const CONTROL_DOWNLEFT = 'downleft';
export const SPEAKER_REQUEST = 'SPEAKER_REQUEST';
export const SPEAKER_SUCCESS = 'SPEAKER_SUCCESS';
export const MIC_REQUEST = 'MIC_REQUEST';
export const MIC_SUCCESS = 'MIC_SUCCESS';
export const BRIGHTNESS_REQUEST = 'BRIGHTNESS_REQUEST';
export const BRIGHTNESS_SUCCESS = 'BRIGHTNESS_SUCCESS';
export const SPEED_REQUEST = 'SPEED_REQUEST';
export const SPEED_SUCCESS = 'SPEED_SUCCESS';
export const FOCUS_REQUEST = 'FOCUS_REQUEST';
export const FOCUS_SUCCESS = 'FOCUS_SUCCESS';
export const DOORBELL_REQUEST = 'DOORBELL_REQUEST';
export const DOORBELL_SUCCESS = 'DOORBELL_SUCCESS';
export const GET_PINS = 'GET_PINS';
export const GET_PINS_SUCCESS = 'GET_PINS_SUCCESS';
export const DELETE_PIN_REQUEST = 'DELETE_PIN_REQUEST';
export const DELETE_PIN_SUCCESS = 'DELETE_PIN_SUCCESS';
export const ADD_PIN_REQUEST = 'ADD_PIN_REQUEST';
export const ADD_PIN_SUCCESS = 'ADD_PIN_SUCCESS';
export const GO_TO_PIN_REQUEST = 'GO_TO_PIN_REQUEST';
export const GO_TO_PIN_SUCCESS = 'GO_TO_PIN_SUCCESS';
export const SELFVIEW_REQUEST = 'SELFVIEW_REQUEST';
export const SELFVIEW_SUCCESS = 'SELFVIEW_SUCCESS';
export const ENTERTAINMENT_REQUEST = 'ENTERTAINMENT_REQUEST';
export const ENTERTAINMENT_SUCCESS = 'ENTERTAINMENT_SUCCESS';
export const SELF_MUTE_AUDIO = 'SELF_MUTE_AUDIO';
export const SELF_MUTE_VIDEO = 'SELF_MUTE_VIDEO';
export const MIC_STATUS = 'MIC_STATUS';
export const CAM_STATUS = 'CAM_STATUS';
export const SELF_VIEW_STATUS = 'SELF_VIEW_STATUS';

// PEXIP
export const PEXIP_UPDATE_PARTICIPANT = 'PEXIP_UPDATE_PARTICIPANT';
export const PEXIP_REMOVE_PARTICIPANT = 'PEXIP_REMOVE_PARTICIPANT';
export const PEXIP_CHAT_MESSAGE = 'CHAT_MESSAGE';
