import { Input, Form } from 'antd';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import careIcon from '@root/assets/images/device-control-page/care_device_pin.svg';
import editIcon from '@root/assets/images/device-control-page/care_device_edit.svg';
import removeIcon from '@root/assets/images/aps-options/remove_mobile.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  deletePin, addPin, goToPin, getPins,
} from '@uhe_actions/DeviceControlActions';
import controlCommand from '@util/ControlCommands';

/**
 * Pins Class Component
 * @returns {void}
 */
class Pins extends PureComponent {
  inputRef = React.createRef();

  formRef = React.createRef();

  /**
   * handle input focus when component is rendered
   * @returns {void}
   */
  componentDidMount() {
    this.inputRef.current.focus();
  }

  /**
   * handleGoToPin
   * @param {string} unique_id pin id
   * @returns {void}
   */
  handleGoToPin = ({ unique_id }) => {
    const { uniqueID, goToPinAction } = this.props;
    const goToPinCommand = controlCommand(uniqueID, 'go_to_bookmark', { bookmark: unique_id });
    goToPinAction({ goToPinCommand });
  }

  /**
   * handleGoToPin
   * @param {string} b_name pin name
   * @returns {void}
   */
  handleAddPin = ({ b_name }) => {
    const {
      uniqueID, addPinAction, editPin, deletePinAction, getPinsAction, customerData, resetEditPin,
    } = this.props;
    const addPinCommand = controlCommand(uniqueID, 'set_as_bookmark', { b_name });
    const delPinCommand = controlCommand(uniqueID, 'delete_bookmark', { b_name: editPin });
    if (editPin) {
      deletePinAction({ delPinCommand });
    }
    addPinAction({ addPinCommand });
    getPinsAction(customerData);
    resetEditPin();
    this.formRef.current.resetFields();
  }

  /**
   * handleGoToPin
   * @param {string} b_name pin name
   * @returns {void}
   */
  handleDeletePin = (b_name) => {
    const {
      uniqueID, deletePinAction, getPinsAction, customerData,
    } = this.props;
    const delPinCommand = controlCommand(uniqueID, 'delete_bookmark', { b_name });
    deletePinAction({ delPinCommand });
    getPinsAction(customerData);
  }

  /**
   * handleGoToPin
   * @param {string} pinName pin name
   * @returns {void}
   */
  handleEditPin = (pinName) => {
    const { toControl } = this.props;
    toControl(pinName);
  }

  /**
   * Handle pins
   * @returns {JSX.Element} Pins Component
   */
  handlePins = () => {
    const { intl, roomPins, id } = this.props;
    const unit = roomPins.filter((room) => room.unique_id === id);
    const bookmarks = unit[0] ? unit[0].bookmarks : [];
    if (bookmarks.length > 0) {
      return bookmarks.map((pin, index) => (
        <div
          key={pin.unique_id}
          className={`${index % 2 !== 0 && 'pins-background'} pins-sections`}
        >
          {pin.name}
          <div className="pins-icons">
            <img src={careIcon} alt={intl.formatMessage({ id: 'device-control.pinLocation' })} onClick={() => this.handleGoToPin(pin)} className="pins-action-icons" />
            <img src={editIcon} alt={intl.formatMessage({ id: 'device-control.edit' })} onClick={() => this.handleEditPin(pin.name)} className="pins-action-icons" />
            <img src={removeIcon} alt={intl.formatMessage({ id: 'device-control.remove' })} onClick={() => this.handleDeletePin(pin.name)} className="pins-action-icons" />
          </div>
        </div>
      ));
    }
    return null;
  }

  /**
   * Renders Pins Component
   * @returns {JSX.Element} Pins Component
   */
  render() {
    const { intl, editPin } = this.props;
    return (
      <>
        <Form onFinish={this.handleAddPin} ref={this.formRef}>
          <Form.Item name="b_name">
            <Input
              placeholder={intl.formatMessage({ id: 'device-control.addPin' })}
              className="pins-input"
              ref={this.inputRef}
              defaultValue={editPin || null}
            />
          </Form.Item>
        </Form>
        {this.handlePins()}
      </>
    );
  }
}

Pins.propTypes = {
  intl: PropTypes.shape().isRequired,
};

/**
 * Maps State to Props
 * @param {string} guest destruct property from reducer
 * @returns {object} Object with States
 */
const mapStateToProps = ({ endpoints }) => ({
  uniqueID: endpoints.endpoint.unique_id,
  customerData: endpoints.endpoint,
});
/**
 * Maps Actions to Props
 * @param {function} dispatch Dispatches Action to Props
 * @returns {object} Object with Actions
 */
const mapDispatchToProps = (dispatch) => ({
  deletePinAction: (payload) => dispatch(deletePin(payload)),
  addPinAction: (payload) => dispatch(addPin(payload)),
  goToPinAction: (payload) => dispatch(goToPin(payload)),
  getPinsAction: (payload) => dispatch(getPins(payload)),
});
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Pins)));
