import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import RestManager from '@util/RestManager';
import { ENDPOINTS, BASE_MOB, BASE_RUBY } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import { getProfileSuccess } from '@actions/uhe/ProfileActions';
import { PROFILE_REQUEST } from '@constants/UHEActionTypes';

/**
 * Sends GET Request to api/me
 * @returns {object} Data
 */
const profileRequest = async () => {
  const request = RestManager.request(`${ENDPOINTS.me}`, 'GET', [], true, BASE_MOB);

  return request;
};

/**
 * Profile Saga Worker
 * @param {object} payload Profile DATA
 * @returns {void}
 */
function* fetchProfile() {
  try {
    const response = yield call(profileRequest);
    if (response) {
      yield put(getProfileSuccess({ ...response }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sagas Watcher
 * @returns {void}
 */
function* sagasWatcher() {
  yield takeEvery(PROFILE_REQUEST, fetchProfile);
}

/**
 * Performs Non-Blocking Call
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(sagasWatcher)]);
}
