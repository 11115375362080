import { PROFILE_REQUEST, PROFILE_SUCCESS } from '@constants/UHEActionTypes';

const INIT_STATE = {
  profile: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROFILE_REQUEST: {
      return { ...state };
    }

    case PROFILE_SUCCESS: {
      return { ...state, profile: { ...action.payload } };
    }

    default:
      return state;
  }
};
