import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import RestManager from '@util/RestManager';
import { ENDPOINTS, BASE_URL, BASE_RUBY } from '@constants/UHEEndpoints';
import { fetchError, showMessage } from '@actions/Common';
import { ENDPOINTS_REQUEST, END_CALL_REQUEST } from '@constants/UHEActionTypes';
import { endpointsSuccess } from '@actions/uhe/EndpointsActions';

/**
 * Sends GET Request to /aps/endpoints/:aps_id
 * @param {object } data aps_id payload
 * @returns {object} Data
 */
const endpointsRequest = async (data) => {
  const request = RestManager.request(`${ENDPOINTS.endpoints}/${data.payload}`, 'GET', [], true, BASE_URL);
  return request;
};

/**
 * Sends POST Request to /api/v1/rooms/control
 * @param {object} body Body Data
 * @returns {object} Data
 */
const endCallRequest = async (body) => {
  const request = RestManager.requestWithoutQueryParams(`${ENDPOINTS.endCall}`, 'POST', body, [], true, true, BASE_RUBY);

  return request;
};

/**
 * getEndpointsData Saga Worker
 * @param {object} data aps_id
 * @returns {void}
 */
function* getEndpointsData(data) {
  try {
    const response = yield call(endpointsRequest, data);
    if (response) {
      yield put(endpointsSuccess({ ...response }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * endCallSaga Saga Worker
 * @param {object} data Body Data
 * @returns {void}
 */
function* endCallSaga(data) {
  try {
    const response = yield call(endCallRequest, data.payload);

    if (response) {
      yield put(showMessage('endCall'));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Sagas Watcher
 * @returns {void}
 */
function* sagasWatcher() {
  yield takeEvery(ENDPOINTS_REQUEST, getEndpointsData);
  yield takeEvery(END_CALL_REQUEST, endCallSaga);
}

/**
 * Performs Non-Blocking Call
 * @returns {void}
 */
export default function* rootSaga() {
  yield all([fork(sagasWatcher)]);
}
