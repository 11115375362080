import {
  TAB_CONTROL, TAB_SETTINGS, ZOOM_REQUEST, ZOOM_SUCCESS, STOP_REQUEST, STOP_SUCCESS,
  PAN_REQUEST, PAN_SUCCESS, SPEAKER_REQUEST, SPEAKER_SUCCESS, MIC_REQUEST, MIC_SUCCESS,
  BRIGHTNESS_REQUEST, BRIGHTNESS_SUCCESS, SPEED_REQUEST, SPEED_SUCCESS, FOCUS_REQUEST,
  FOCUS_SUCCESS, DOORBELL_REQUEST, DOORBELL_SUCCESS, GET_PINS, GET_PINS_SUCCESS,
  DELETE_PIN_REQUEST, DELETE_PIN_SUCCESS, ADD_PIN_REQUEST, ADD_PIN_SUCCESS,
  GO_TO_PIN_REQUEST, GO_TO_PIN_SUCCESS, ENTERTAINMENT_REQUEST, ENTERTAINMENT_SUCCESS,
  SELFVIEW_REQUEST, SELFVIEW_SUCCESS, SELF_MUTE_AUDIO, SELF_MUTE_VIDEO,
  MIC_STATUS, CAM_STATUS, SELF_VIEW_STATUS,
} from '@constants/UHEActionTypes';

/**
 * setSelfViewStatus Action Creator
 * @param {object} payload Data
 * @returns {object} setSelfViewStatus
 */
export const setSelfViewStatus = (payload) => ({
  type: SELF_VIEW_STATUS,
  payload,
});

/**
 * setCamStatus Action Creator
 * @param {object} payload Data
 * @returns {object} setCamStatus
 */
export const setCamStatus = (payload) => ({
  type: CAM_STATUS,
  payload,
});

/**
 * setMicStatus Action Creator
 * @param {object} payload Data
 * @returns {object} setMicStatus
 */
export const setMicStatus = (payload) => ({
  type: MIC_STATUS,
  payload,
});

/**
 * selfMuteAudio Action Creator
 * @param {object} payload Data
 * @returns {object} goToPin
 */
export const selfMuteAudio = (payload) => ({
  type: SELF_MUTE_AUDIO,
  payload,
});

/**
 * selfMuteVideo Action Creator
 * @param {object} payload Data
 * @returns {object} goToPin
 */
export const selfMuteVideo = (payload) => ({
  type: SELF_MUTE_VIDEO,
  payload,
});
/**
 * goToPin Action Creator
 * @param {object} payload Data
 * @returns {object} goToPin
 */
export const goToPin = (payload) => ({
  type: GO_TO_PIN_REQUEST,
  payload,
});

/**
 * getSelfView Action Creator
 * @param {object} payload Data
 * @returns {object} getSelfView Data
 */
export const getSelfView = (payload) => ({
  type: SELFVIEW_REQUEST,
  payload,
});

/**
 * getEntertainment Action Creator
 * @param {object} payload Data
 * @returns {object} getEntertainment Data
 */
export const getEntertainment = (payload) => ({
  type: ENTERTAINMENT_REQUEST,
  payload,
});

/**
 * goToPinSuccess Action Creator
 * @param {object} payload Data
 * @returns {object} goToPinSuccess Data
 */
export const goToPinSuccess = (payload) => ({
  type: GO_TO_PIN_SUCCESS,
  payload,
});

/**
 * addPin Action Creator
 * @param {object} payload Data
 * @returns {object} addPin
 */
export const addPin = (payload) => ({
  type: ADD_PIN_REQUEST,
  payload,
});

/**
 * addPinSuccess Action Creator
 * @param {object} payload Data
 * @returns {object} addPinSuccess Data
 */
export const addPinSuccess = (payload) => ({
  type: ADD_PIN_SUCCESS,
  payload,
});

/**
 * deletePins Action Creator
 * @param {object} payload Data
 * @returns {object} deletePinsData
 */
export const deletePin = (payload) => ({
  type: DELETE_PIN_REQUEST,
  payload,
});

/**
 * deletePinsSuccess Action Creator
 * @param {object} payload Data
 * @returns {object} deletePinsSuccess Data
 */
export const deletePinSuccess = (payload) => ({
  type: DELETE_PIN_SUCCESS,
  payload,
});

/**
 * getPins Action Creator
 * @param {object} payload Data
 * @returns {object} getPins Data
 */
export const getPins = (payload) => ({
  type: GET_PINS,
  payload,
});

/**
 * getPins Action Creator
 * @param {object} payload Data
 * @returns {object} getPins Data
 */
export const getPinsSuccess = (payload) => ({
  type: GET_PINS_SUCCESS,
  payload,
});
/**
 * getSelfViewSuccess Action Creator
 * @returns {object} getSelfViewSuccess Data
 */
export const getSelfViewSuccess = () => ({
  type: SELFVIEW_SUCCESS,
});
/**
 * getEntertainmentSuccess Action Creator
 * @returns {object} getEntertainmentSuccess Data
 */
export const getEntertainmentSuccess = () => ({
  type: ENTERTAINMENT_SUCCESS,
});
/**
 * getDoorbell Action Creator
 * @param {object} payload Data
 * @returns {object} getDoorbell Data
 */
export const getDoorbell = (payload) => ({
  type: DOORBELL_REQUEST,
  payload,
});

/**
 * getDoorbellSuccess Action Creator
 * @returns {object} getDoorbellSuccess Data
 */
export const getDoorbellSuccess = () => ({
  type: DOORBELL_SUCCESS,
});

/**
 * getFocus Action Creator
 * @param {object} payload Data
 * @returns {object} getFocus Data
 */
export const getFocus = (payload) => ({
  type: FOCUS_REQUEST,
  payload,
});

/**
 * getFocusSuccess Action Creator
 * @returns {object} getFocusSuccess Data
 */
export const getFocusSuccess = () => ({
  type: FOCUS_SUCCESS,
});

/**
 * getSpeed Action Creator
 * @param {object} payload Data
 * @returns {object} getSpeed Data
 */
export const getSpeed = (payload) => ({
  type: SPEED_REQUEST,
  payload,
});

/**
 * getSpeedSuccess Action Creator
 * @returns {object} getSpeedSuccess Data
 */
export const getSpeedSuccess = () => ({
  type: SPEED_SUCCESS,
});

/**
 * getBright Action Creator
 * @param {object} payload Data
 * @returns {object} getBright Data
 */
export const getBright = (payload) => ({
  type: BRIGHTNESS_REQUEST,
  payload,
});

/**
 * getBrightSuccess Action Creator
 * @returns {object} getBrightSuccess Data
 */
export const getBrightSuccess = () => ({
  type: BRIGHTNESS_SUCCESS,
});

/**
 * getMic Action Creator
 * @param {object} payload Data
 * @returns {object} getMic Data
 */
export const getMic = (payload) => ({
  type: MIC_REQUEST,
  payload,
});

/**
 * getMicSuccess Action Creator
 * @returns {object} getMicSuccess Data
 */
export const getMicSuccess = () => ({
  type: MIC_SUCCESS,
});

/**
 * tabControl Action Creator
 * @param {object} payload Data
 * @returns {object} tabContrl Data
 */
export const tabControl = (payload) => ({
  type: TAB_CONTROL,
  payload,
});
/**
 * getZoom action
 * @param {object} payload data
 * @returns {object} zoom data
 */
export const getZoom = (payload) => ({
  type: ZOOM_REQUEST,
  payload,
});

/**
 * tabSettings Action Creator
 * @param {object} payload Data
 * @returns {void} tabSettings Data
 */
export const tabSettings = (payload) => ({
  type: TAB_SETTINGS,
  payload,
});

/**
 * getZoomSuccess action
 * @returns {object} zoom data
 */
export const getZoomSuccess = () => ({
  type: ZOOM_SUCCESS,
});

/**
 * getStop action
 * @param {object} payload data
 * @returns {object} stop data
 */
export const getStop = (payload) => ({
  type: STOP_REQUEST,
  payload,
});

/**
 * getStopSuccess action
 * @returns {object} stop data
 */
export const getStopSuccess = () => ({
  type: STOP_SUCCESS,
});

/**
 * getPan action
 * @param {object} payload data
 * @returns {object} pan data
 */
export const getPan = (payload) => ({
  type: PAN_REQUEST,
  payload,
});

/**
 * getPanSuccess action
 * @returns {object} pan data
 */
export const getPanSuccess = () => ({
  type: PAN_SUCCESS,
});

/**
 * getSpeaker action
 * @param {object} payload data
 * @returns {object} pan data
 */
export const getSpeaker = (payload) => ({
  type: SPEAKER_REQUEST,
  payload,
});

/**
 * getSpeakerSuccess action
 * @returns {object} pan data
 */
export const getSpeakerSuccess = () => ({
  type: SPEAKER_SUCCESS,
});
