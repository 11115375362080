import React, { useState, useEffect } from 'react';
import { Menu, Button } from 'antd';
import { Link } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '@constants/ThemeSetting';
import IntlMessages from '@util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExceptionOutlined, DesktopOutlined, ExclamationCircleOutlined, FundViewOutlined,
} from '@ant-design/icons';
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from 'appRedux/actions/Setting';
import {
  shouldRenderNotificationsLink,
  shouldRenderMonitoringLink,
  shouldRenderRoomBedLink,
  shouldRenderUnitLink,
  shouldRenderFacilityLink,
  shouldRenderCustomerLink,
  shouldRenderOrganizationLink,
  shouldRenderUsersLink,
  shouldRenderSSOProvidersLink,
  shouldRenderReportingLink,
  shouldRenderSystemLink,
  shouldRenderIObserverLink,
  shouldRenderTechnicianLink,
  shouldRenderDeveloperLink,
  shouldRenderSettingsLink,
} from '@util/UheRoleChecker';
import UserProfile from './UserProfile';
import SidebarLogo from './SidebarLogo';

const { SubMenu } = Menu;

/**
 * @description Handles sidebar menu
 * @returns {JSX}
 */
const SidebarContent = () => {
  const dispatch = useDispatch();

  const {
    width, navCollapsed, themeType, pathname,
  } = useSelector(
    ({ settings }) => settings,
  );

  const [closedBedsCartsSubmenu, setClosedBedsCartsSubmenu] = useState(true);
  const [closedSettingsSubmenu, setClosedSettingsSubmenu] = useState(true);
  const [closedReportingSubmenu, setClosedReportingSubmenu] = useState(true);
  const loggedUser = useSelector(({ ConfigurationUsers }) => ConfigurationUsers.ownUser);

  const [isCaregilitySystemAdmin, setCaregilitySystemAdmin] = useState(false);
  const [isUserAdmin, setUserAdmin] = useState(false);
  const [isCaregilityAdmin, setCaregilityAdmin] = useState(false);
  const [isOrganizationAdmin, setOrganizationAdmin] = useState(false);
  const [isCustomerAdmin, setCustomerAdmin] = useState(false);
  const [isFacilityAdmin, setFacilityAdmin] = useState(false);
  const [isUnitAdmin, setUnitAdmin] = useState(false);
  const [isTechnicianAdmin, setTechnicianAdmin] = useState(false);
  const [isTechnician, setTechnician] = useState(false);

  useEffect(() => {
    if (loggedUser.roles) {
      const caregilitySystemAdmin = loggedUser.isCaregilitySystemAdmin;
      const userAdmin = loggedUser.isUserAdmin;
      const caregilityAdmin = loggedUser.isCaregilityAdmin;
      const customerAdmin = loggedUser.isCustomerAdmin;
      const organizationAdmin = loggedUser.isOrganizationAdmin;
      const facilityAdmin = loggedUser.isFacilityAdmin;
      const unitAdmin = loggedUser.isUnitAdmin;
      const technicianAdmin = loggedUser.isTechnicianAdmin;
      const technician = loggedUser.isTechnician;

      if (caregilitySystemAdmin && !isCaregilitySystemAdmin) {
        setCaregilitySystemAdmin(true);
      }

      if (userAdmin && !isUserAdmin) {
        setUserAdmin(true);
      }

      if (caregilityAdmin && !isCaregilityAdmin) {
        setCaregilityAdmin(true);
      }

      if (customerAdmin && !isCustomerAdmin) {
        setCustomerAdmin(true);
      }

      if (organizationAdmin && !isOrganizationAdmin) {
        setOrganizationAdmin(true);
      }

      if (facilityAdmin && !isFacilityAdmin) {
        setFacilityAdmin(true);
      }

      if (unitAdmin && !isUnitAdmin) {
        setUnitAdmin(true);
      }

      if (technicianAdmin && !isTechnicianAdmin) {
        setTechnicianAdmin(true);
      }

      if (technician && !isTechnician) {
        setTechnician(true);
      }
    }
  });

  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  /**
   * @description Gets no headers class
   * @param {string} navStyle
   * @returns {string}
   */
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
      || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  let selectedKeys = pathname.substr(1);
  const tokens = selectedKeys.split('/');
  const defaultOpenKeys = tokens[1];

  selectedKeys = selectedKeys.replace('/new', '');
  selectedKeys = selectedKeys.replace('/edit', '');
  selectedKeys = selectedKeys.replace('/bulk/upload', '');
  selectedKeys = selectedKeys.replace(/\/[0-9]+/g, '');

  if (selectedKeys[selectedKeys.length - 1] === '/') {
    selectedKeys = selectedKeys.substring(0, selectedKeys.length - 1);
  }

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <Button
            shape="circle"
            className={`config-icon prev-icon ${navStyle === 'NAV_STYLE_FIXED' ? 'nav-slider' : 'nav-slider-collapsed'}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else {
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          >
            <i className={`icon ${navStyle === 'NAV_STYLE_FIXED' ? 'icon-charvlet-left' : 'icon-charvlet-right'}`} />
          </Button>
        </div>

        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys, 'bedsCarts']}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          />
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
